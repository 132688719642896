import { Box, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { StructureForm } from "../projects/StructureForm";
import { ProfessionnelForm } from "../projects/ProfessionnelForm";
import { StructureCalendar } from "../scheduler/StructureCalendar";
import { ProfessionnelCalendar } from "../scheduler/ProfessionnelCalendar";

export const ProjectPage = () => {
    const userSelector = useAppSelector((state: RootState) => state.user.data);
    const userProData = useAppSelector(
        (state: RootState) => state.userPro.data
    );

    const [, setAccount] = React.useState(userSelector);
    const [, setIsDisplayPopUpCreateProject] = React.useState(false);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const numberOfProjects = () => {
        if (
            userSelector.societe &&
            Array.isArray(userSelector.disponibilities)
        ) {
            return userSelector.disponibilities.length;
        }
        if (Array.isArray(userProData.disponibilities)) {
            return userProData.disponibilities.length;
        }
        return 0;
    };

    React.useEffect(() => {
        setAccount(userSelector.societe ? userSelector : userProData);

        if (userSelector.isCreateProject || userProData.isCreateProject) {
            setIsDisplayPopUpCreateProject(true);
        }
    }, [dispatch, userSelector.id, userProData.id, userProData, userSelector]);

    return (
        <>
            <Box
                sx={{
                    width: "100%",
                }}
            >
                <Box sx={{ justifySelf: "center", mt: 6 }}>
                    {userSelector.societe ? (
                        <StructureForm />
                    ) : (
                        <ProfessionnelForm />
                    )}
                    <Typography
                        sx={{
                            mt: 5,
                            mb: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {t("project.on")}
                        <span
                            style={{
                                backgroundColor: "#3d3d3d",
                                padding: 3,
                                color: "white",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: "2px",
                                marginRight: "2px",
                            }}
                        >
                            {" " + numberOfProjects() + " "}
                        </span>{" "}
                        {t("project.on.going")}
                    </Typography>
                    <Box
                        sx={{
                            width: "100%",
                            height: "auto",
                            mb: 10,
                            mt: 4,
                        }}
                    >
                        {userSelector.societe ? (
                            <StructureCalendar
                                disponibilities={userSelector.disponibilities}
                            />
                        ) : (
                            <ProfessionnelCalendar
                                disponibilities={userProData.disponibilities}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};
