import { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../service/apiService";
import { useTranslation } from "react-i18next";

interface GeoInfo {
    countryCode: string;
}

const useIpLanguageDetection = () => {
    const [ipAddress, setIpAddress] = useState("");
    const [geoInfo, setGeoInfo] = useState<GeoInfo | null>(null);

    const { i18n } = useTranslation();

    useEffect(() => {
        const fetchIP = async () => {
            try {
                const response = await axios.get("https://api.ipify.org");
                setIpAddress(response.data);
            } catch (error) {
                console.error("Failed to fetch IP:", error);
            }
        };
        fetchIP();
    }, []);

    useEffect(() => {
        const fetchIPInfo = async () => {
            try {
                const response = await api("/api/ip/infos/" + ipAddress, "GET");
                setGeoInfo(response.data);
            } catch (error) {
                console.error("Failed to Location info:", error);
            }
        };

        if (ipAddress) {
            fetchIPInfo();
        }
    }, [ipAddress]);

    useEffect(() => {
        if (geoInfo?.countryCode) {
            i18n.changeLanguage(geoInfo.countryCode.toLowerCase());
        }
    }, [geoInfo, i18n]);

    return null;
};

export default useIpLanguageDetection;
