import { CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import HandShakeIcon from "../assets/handshakeWhite.png";
import Rejected from "../assets/Rejected.svg";
import { FunctionComponent, useState } from "react";
import fleche2 from "../assets/fleche2.png";
import { useNavigate } from "react-router-dom";
import { RouteApp } from "../common/model/RouteApp";
import { Modal } from "../common/components/Modal";
import { ConfirmationModal } from "../common/components/ConfirmationModal";
import { getEmptyAvatarStructure } from "../utils/FormatUtils";
import "./StructureCard.css";
import { Item, ItemNight } from "./WorkerCard";
import { postSmart } from "../search/redux/searchAction";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useAppDispatch } from "../common/redux/reduxHooks";
import { DisponibiliteStructure } from "../structures/model/StructureModel";

export const StructureCard: FunctionComponent<DisponibiliteStructure> = (
    props: DisponibiliteStructure
) => {
    const [isDisplayPopupSmart, setIsDisplayPopupSmart] =
        useState<boolean>(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] =
        useState<boolean>(false);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    function capitalizeFirstLetter(string: string) {
        if (string) {
            return string[0].toUpperCase() + string.slice(1);
        }
    }

    const onConfirmAction = () => {
        setIsConfirmModalOpen(false);
        dispatch(postSmart({ id: props.id, role: "ROLE_PROFESSIONNEL" })).then(
            (res: any) => {
                if (!res.error) {
                    setIsDisplayPopupSmart(true);
                }
            }
        );
    };
    const onValidatePopUpSmart = () => {
        setIsDisplayPopupSmart(false);
        navigate(RouteApp.PROFESSIONNELS);
    };

    const refStatus = {
        accepted: "APPROUVED",
        refused: "REFUSED",
        wait: "WAIT",
    };

    let config = {
        color: "#58b2a6",
        message: t("message.smart"),
        logo: true,
        logoIcon: HandShakeIcon,
    };

    if (props.isInterested) {
        if (props.isInterested.status === refStatus.wait) {
            config = {
                color: "#97be5a",
                message: t("message.send.smart"),
                logo: false,
                logoIcon: HandShakeIcon,
            };
        } else if (props.isInterested.status === refStatus.accepted) {
            config = {
                color: "#58b2a6",
                message: t("message.accepted.smart"),
                logo: false,
                logoIcon: HandShakeIcon,
            };
        } else if (props.isInterested.status === refStatus.refused) {
            config = {
                color: "#FF3131",
                message: t("message.refused.smart"),
                logo: false,
                logoIcon: Rejected,
            };
        }
    }
    if (props.fulfilled) {
        config = {
            color: "#58b2a6",
            message: t("message.provided.smart"),
            logo: false,
            logoIcon: HandShakeIcon,
        };
    }

    const smart = () => {
        if (config.message === t("message.smart")) {
            setIsConfirmModalOpen((prevState) => !prevState);
        }
    };

    return (
        <CardContainer elevation={3} onClick={() => smart()}>
            <CardActionArea>
                <CardHeader
                    subheaderTypographyProps={{
                        width: "auto",
                        maxWidth: "none",
                    }}
                    sx={{ p: 0 }}
                    avatar={
                        <img
                            src={
                                props.profilImageUrl
                                    ? props.profilImageUrl
                                    : getEmptyAvatarStructure()
                            }
                            alt="Profile"
                            id="profilePictureStruct"
                            style={{ maxHeight: 90, maxWidth: 90 }}
                        />
                    }
                    title={
                        <Grid
                            item
                            md={5}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                mt: 1,
                            }}
                        >
                            <Typography
                                gutterBottom
                                variant="body2"
                                sx={{
                                    backgroundColor: "#36454F",
                                    color: "white",
                                    p: 1,
                                    borderRadius: "5px",
                                }}
                            >
                                {new Date(
                                    props.dateDemarrage
                                ).toLocaleDateString("fr-FR")}
                            </Typography>
                            <div>
                                <img
                                    src={fleche2}
                                    alt="Arrow"
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        zIndex: "10",
                                        margin: 0.2,
                                    }}
                                ></img>
                            </div>
                            <Typography
                                gutterBottom
                                variant="body2"
                                sx={{
                                    backgroundColor: "#36454F",
                                    color: "white",
                                    p: 1,
                                    borderRadius: "5px",
                                }}
                            >
                                {props.dateFin
                                    ? new Date(
                                          props.dateFin
                                      ).toLocaleDateString("fr-FR")
                                    : t("select.indifferent")}
                            </Typography>
                        </Grid>
                    }
                    subheader={
                        <Grid item sx={{ width: "auto" }}>
                            <Typography
                                gutterBottom
                                noWrap={true}
                                sx={{
                                    borderTop: "0.1em solid gray",
                                    borderBottom: "0.1em solid gray",
                                    pt: 1,
                                    fontWeight: "bold",
                                    width: "auto",
                                    pb: 1,
                                }}
                            >
                                {props.ville}
                            </Typography>
                            <Typography
                                gutterBottom
                                sx={{
                                    pt: 0,
                                    fontWeight: "bold",
                                    width: "auto",
                                    pb: 1,
                                    borderBottom: "0.1em solid gray",
                                }}
                            >
                                {t(`${props.profession}`)}
                            </Typography>
                        </Grid>
                    }
                />
                <CardContent>
                    <Description align="center">
                        {props.description}
                    </Description>
                    <Grid
                        container
                        mb={1}
                        spacing={1}
                        columns={16}
                        sx={{
                            justifyContent: "center",
                            gap: "0.5rem",
                        }}
                        flexWrap={"nowrap"}
                    >
                        {props.selection &&
                            props.selection.map((e) => {
                                return e !== t("select.night") ? (
                                    <Item>{capitalizeFirstLetter(e)}</Item>
                                ) : (
                                    <ItemNight>
                                        {capitalizeFirstLetter(e)}
                                    </ItemNight>
                                );
                            })}
                    </Grid>
                </CardContent>
                <Grid
                    container
                    columns={16}
                    bgcolor={config.color}
                    textAlign={"center"}
                    pt={1}
                    pb={1}
                >
                    <Grid item xs={15.8}>
                        <Typography
                            variant="h6"
                            color={"white"}
                            sx={{
                                fontWeight: "bold",
                                justifyContent: "space-between",
                                display: "inline-flex",
                                alignItems: "center",
                            }}
                        >
                            {config.message}{" "}
                            {config.logo && (
                                <img
                                    src={config.logoIcon}
                                    alt="Handshake Smart Santé"
                                    style={{
                                        height: "20%",
                                        width: "20%",
                                        marginLeft: "10px",
                                    }}
                                ></img>
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActionArea>

            <ConfirmationModal
                open={isConfirmModalOpen}
                title={t("message.confirm.action")}
                onClose={() => setIsDisplayPopupSmart(false)}
                onValidation={onConfirmAction}
            ></ConfirmationModal>

            {props.isInterested === null && (
                <Modal
                    open={isDisplayPopupSmart}
                    title={t("message.congratulations.smart")}
                    onClose={() => setIsDisplayPopupSmart(false)}
                    onValidation={() => onValidatePopUpSmart()}
                    titleButtonValidation={t("button.modal.valid")}
                ></Modal>
            )}
        </CardContainer>
    );
};

const CardContainer = styled(Card)({
    margin: "1rem",
    borderRadius: "15px",
    display: "inline-flex",
    width: "340px",
    opacity: "!enabled ? 0.65 : 1",
    ":hover": {
        boxShadow: "enabled ? 20 : 0",
    },

    "@media(width >= 990px)": {
        width: "380px",
    },
});

const Description = styled(Typography)({
    overflow: "auto",
    height: "160px",
    marginBottom: "1rem",
});
