import {
    Autocomplete,
    Button,
    Grid,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    createFilterOptions,
    styled,
} from "@mui/material";

import "moment/locale/fr";
import { Departements, DepartementsFromGovernment } from "../App";
import { Modal } from "../common/components/Modal";
import { useAppDispatch } from "../common/redux/reduxHooks";
import { createProjectProfessionnel } from "../structures/redux/projectAction";
import { language, LanguageType } from "../utils/LanguageUtils";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import StyledDatePicker from "../common/components/DatePicker";
import TaskAltSharpIcon from "@mui/icons-material/TaskAltSharp";
import { useTranslation } from "react-i18next";
import React from "react";
import { CreateDisponibiliteProfessionnel } from "../professionnels/model/ProfessionnelModel";
import dayjs from "dayjs";

const initialValueProfessionnel: CreateDisponibiliteProfessionnel = {
    departements: [""],
    profession: [""],
    selection: [""],
    description: "",
    type: "",
    dateDemarrage: "",
    dateFin: "",
    heureDemarrage: "",
    heureFin: "",
};

export const ProfessionnelForm = () => {
    const [alignmentJour, setAlignmentJour] = React.useState("");
    const [alignmentSemaine, setAlignmentSemaine] = React.useState("");
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [dayOpen, setDayOpen] = React.useState(false);
    const [weekOpen, setWeekOpen] = React.useState(false);
    const [startDateValue, setStartDateValue] =
        React.useState<dayjs.Dayjs | null>(null);
    const [endDateValue, setEndDateValue] = React.useState<dayjs.Dayjs | null>(
        null
    );
    const [heureDemarrage, setHeureDemarrage] =
        React.useState<dayjs.Dayjs | null>(null);
    const [heureFin, setHeureFin] = React.useState<dayjs.Dayjs | null>(null);
    const [isAutocompleteDepartementsOpen, setIsAutocompleteDepartementsOpen] =
        React.useState<boolean>(false);
    const [titleModal, setTitleModal] = React.useState<string>("");
    const [messageModal, setMessageModal] = React.useState<string>("");
    const dispatch = useAppDispatch();
    const [isDisplayPopUpCreateProject, setIsDisplayPopUpCreateProject] =
        React.useState(false);
    const [arrayOfErrorMessage, setArrayOfErrorMessage] = React.useState<
        string[]
    >([]);
    const [error, setError] = React.useState<{ [key: string]: string | null }>({
        description: null,
    });

    const { t } = useTranslation();
    const lang: LanguageType = language();

    const [projectData, setProjectData] =
        React.useState<CreateDisponibiliteProfessionnel>(
            initialValueProfessionnel
        );

    const handleAlignmentJour = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null
    ) => {
        if (newAlignment !== null) {
            setAlignmentJour(newAlignment);
        }
    };

    const handleAlignmentSemaine = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null
    ) => {
        if (newAlignment !== null) {
            setAlignmentSemaine(newAlignment);
        }
    };

    const changeDatePicker = (e: dayjs.Dayjs | null, type: string) => {
        if (e) {
            if (type === "startdate") {
                setStartDateValue(e);
            } else if (type === "enddate") {
                setEndDateValue(e);
            }
        }
    };

    const handleHoursChange = (value: dayjs.Dayjs | null, type: string) => {
        if (value) {
            const timeFormatted = value.format("HH:mm");
            if (type === "heureDemarrage") {
                setHeureDemarrage(value);
                setProjectData({
                    ...projectData,
                    heureDemarrage: timeFormatted,
                });
            } else if (type === "heureFin") {
                setHeureFin(value);
                setProjectData({
                    ...projectData,
                    heureFin: timeFormatted,
                });
            }
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (isSubmitting) return;

        let errors: { [key: string]: string | null } = {};

        if (!projectData.description || projectData.description.trim() === "") {
            errors.description = "Description is important";
        }

        if (Object.keys(errors).length > 0) {
            setError(errors);
            return;
        }

        setError({ description: null });
        setIsSubmitting(true);

        let selection =
            alignmentJour === alignmentSemaine
                ? ["indifferent"]
                : [alignmentJour, alignmentSemaine];

        const values = {
            ...projectData,
            selection: selection,
            dateDemarrage: startDateValue
                ? startDateValue.format("YYYY-MM-DD")
                : null,
            dateFin: endDateValue ? endDateValue.format("YYYY-MM-DD") : null,
            heureDemarrage: heureDemarrage?.format("HH:mm:ss") ?? null,
            heureFin: heureFin?.format("HH:mm:ss") ?? null,
            profession: Array.isArray(projectData.profession)
                ? projectData.profession
                : [projectData.profession],
            description: projectData.description || "",
        };

        dispatch(
            createProjectProfessionnel({
                ...values,
                profession: values.profession,
            })
        )
            .then((res: any) => {
                if (!res.error) {
                    setProjectData(initialValueProfessionnel);
                    setAlignmentJour("");
                    setAlignmentSemaine("");
                    setStartDateValue(null);
                    setEndDateValue(null);
                    setArrayOfErrorMessage([]);
                    setIsDisplayPopUpCreateProject(true);
                    setMessageModal(lang.createProjectValidation);
                    setTitleModal(lang.congratulation);
                } else {
                    setIsDisplayPopUpCreateProject(true);
                    let errors: string[] = [];
                    res.payload.response.data.violations.forEach(
                        (element: { message: string }, i: number) => {
                            errors.push(element.message);
                        }
                    );
                    setMessageModal("Liste des erreurs :");
                    setArrayOfErrorMessage(errors);
                    setTitleModal("Erreur");
                }
                setIsSubmitting(false);
            })
            .catch(() => {
                setIsSubmitting(false);
            });
    };

    const filterOptions = createFilterOptions({
        matchFrom: "start",
        limit: 105,
        stringify: (Departements: DepartementsFromGovernment) =>
            Departements.nom,
    });

    return (
        <ProjectContainer>
            <Grid item sx={{ mt: 6 }}>
                <Typography
                    sx={{
                        gridArea: "title",
                        fontSize: "3ex",
                        width: "max-content",
                        fontWeight: "bold",
                        color: "white",
                    }}
                >
                    {lang.createProject}
                </Typography>
            </Grid>

            <InputsSection>
                <InputsContainer>
                    <Stack
                        sx={{
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "1rem",
                        }}
                    >
                        <Stack sx={{ flexDirection: "row", gap: "1rem" }}>
                            <Autocomplete
                                sx={{
                                    backgroundColor: "white",
                                    borderRadius: "5px",
                                    width: "330px",
                                    height: "fit-content",
                                    color: "#3b3c36",
                                    "& fieldset": { border: "none" },
                                    "@media(width >= 1200px)": {
                                        minWidth: "410px",
                                    },
                                    "@media(width >= 1900px)": {
                                        minWidth: "570px",
                                    },
                                }}
                                disablePortal
                                id="combo-box"
                                onInputChange={(event, newInputValue) => {
                                    setProjectData({
                                        ...projectData,
                                        departements: [newInputValue],
                                    });
                                    if (event.type === "click")
                                        setIsAutocompleteDepartementsOpen(true);
                                }}
                                noOptionsText={"Aucune proposition"}
                                open={isAutocompleteDepartementsOpen}
                                onFocus={() =>
                                    setIsAutocompleteDepartementsOpen(true)
                                }
                                onBlur={() =>
                                    setIsAutocompleteDepartementsOpen(false)
                                }
                                includeInputInList
                                getOptionLabel={(option) => option.nom}
                                filterSelectedOptions
                                filterOptions={filterOptions}
                                options={Departements}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="Departements"
                                        id="Departements"
                                        placeholder={t("search.departements")}
                                    />
                                )}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.code}>
                                            {option.nom}
                                        </li>
                                    );
                                }}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setProjectData({
                                            ...projectData,
                                            departements: [newValue.nom],
                                        });
                                        setIsAutocompleteDepartementsOpen(
                                            false
                                        );
                                    }
                                }}
                            />
                        </Stack>
                        <Stack
                            sx={{
                                minWidth: "max-content",
                                flexDirection: "row",
                                gap: "1rem",
                            }}
                            flexWrap={"nowrap"}
                        >
                            <StyledDatePicker
                                open={dayOpen}
                                value={startDateValue}
                                onOpen={() => setDayOpen(true)}
                                onClose={() => setDayOpen(false)}
                                onChange={(newValue) =>
                                    changeDatePicker(newValue, "startdate")
                                }
                                label={t("project.date.start")}
                                showTime={false}
                            />

                            <StyledDatePicker
                                open={weekOpen}
                                value={endDateValue}
                                onOpen={() => setWeekOpen(true)}
                                onClose={() => setWeekOpen(false)}
                                onChange={(newValue) =>
                                    changeDatePicker(newValue, "enddate")
                                }
                                label={t("project.date.end")}
                                showTime={false}
                            />
                        </Stack>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack direction="row" spacing={2}>
                                <TimePicker
                                    sx={{
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        width: "160px",
                                        height: "fit-content",
                                        color: "#3b3c36",
                                        "& fieldset": { border: "none" },
                                        "@media(width >= 1200px)": {
                                            width: "200px",
                                        },
                                        "@media(width >= 1900px)": {
                                            width: "280px",
                                        },
                                    }}
                                    ampm={false}
                                    label={t("project.hour.start")}
                                    value={heureDemarrage}
                                    onChange={(newValue) =>
                                        handleHoursChange(
                                            newValue,
                                            "heureDemarrage"
                                        )
                                    }
                                />
                                <TimePicker
                                    sx={{
                                        backgroundColor: "white",
                                        borderRadius: "5px",
                                        width: "160px",
                                        height: "fit-content",
                                        color: "#3b3c36",
                                        "& fieldset": { border: "none" },
                                        "@media(width >= 1200px)": {
                                            width: "200px",
                                        },
                                        "@media(width >= 1900px)": {
                                            width: "280px",
                                        },
                                    }}
                                    ampm={false}
                                    label={t("project.hour.end")}
                                    value={heureFin}
                                    onChange={(newValue) =>
                                        handleHoursChange(newValue, "heureFin")
                                    }
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Stack>
                    {/* Jour / Nuit*/}
                    <Stack
                        sx={{
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "1rem",
                        }}
                    >
                        <ButtonGroup
                            value={alignmentJour}
                            exclusive
                            id="Jour"
                            size="medium"
                            onChange={handleAlignmentJour}
                            color="warning"
                            aria-label="text alignment"
                        >
                            <MyToggleButton
                                value="jour"
                                aria-label="left aligned"
                            >
                                {t("select.day")}
                            </MyToggleButton>
                            <MyToggleButton value="nuit" aria-label="centered">
                                {t("select.night")}
                            </MyToggleButton>
                            <MyToggleButton
                                value="indifferent"
                                aria-label="right aligned"
                            >
                                {t("select.indifferent")}
                            </MyToggleButton>
                        </ButtonGroup>

                        <ButtonGroup
                            value={alignmentSemaine}
                            exclusive
                            id="Semaine"
                            size="medium"
                            onChange={handleAlignmentSemaine}
                            color="warning"
                            aria-label="text alignment"
                        >
                            <MyToggleButton
                                value="semaines"
                                aria-label="left aligned"
                            >
                                {t("select.period.weekend")}
                            </MyToggleButton>
                            <MyToggleButton
                                value="weekend"
                                aria-label="centered"
                            >
                                Week-End
                            </MyToggleButton>
                            <MyToggleButton
                                value="indifferent"
                                aria-label="right aligned"
                            >
                                {t("select.indifferent")}
                            </MyToggleButton>
                        </ButtonGroup>
                    </Stack>
                </InputsContainer>
                <TextareaSection>
                    <Textarea>
                        <TextField
                            placeholder={t("project.description")}
                            defaultValue={projectData.description}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setProjectData({
                                    ...projectData,
                                    description: event.target.value,
                                });
                                setError((prev) => ({
                                    ...prev,
                                    description: null,
                                }));
                            }}
                            minRows={2}
                            maxRows={3}
                            inputProps={{ maxLength: 100 }}
                            name="description"
                            id="outlined-multiline-flexible"
                            multiline
                            error={!!error.description}
                            helperText={error.description}
                        />
                    </Textarea>
                    <Submit
                        type="submit"
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                    >
                        <TaskAltSharpIcon
                            fontSize="small"
                            sx={{
                                width: 58,
                                height: 58,
                            }}
                        />
                    </Submit>
                    <Modal
                        open={isDisplayPopUpCreateProject}
                        title={titleModal}
                        message={messageModal}
                        showBackground={true}
                        arrayOfMessage={arrayOfErrorMessage}
                        credentials=" "
                        onClose={() => setIsDisplayPopUpCreateProject(false)}
                        onValidation={() => {
                            setIsDisplayPopUpCreateProject(false);
                        }}
                        titleButtonValidation={t("button.modal.valid")}
                    ></Modal>
                </TextareaSection>
            </InputsSection>
        </ProjectContainer>
    );
};

const ProjectContainer = styled(Stack)({
    display: "flex",
    flexDirection: "column",
    marginTop: "3rem",
    gap: "1rem",
    padding: "1rem",
    alignItems: "center",
    width: "100dvw",
    background: "#FF8A80",
});

const InputsSection = styled(Stack)({
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.5rem",
    marginTop: "1rem",

    "@media(width >= 990px)": {
        flexDirection: "row",
    },
});

const InputsContainer = styled(Stack)({
    flexDirection: "column",
    width: "100%",
    gap: "1.5rem",
    justifyContent: "center",
    alignItems: "center",

    "@media(width >= 990px)": {
        flexDirection: "row",
        width: "60%",
    },

    "@media(width >= 1900px)": {
        width: "50%",
    },
});

const MyToggleButton = styled(ToggleButton)({
    background: "white",
    boxShadow:
        "rgba(0, 0, 0, 0.2) 0px 2px 2px, rgba(0, 0, 0, 0.3) 0px 3px 3px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
    borderRadius: "5px",
    fontSize: "13px",
    width: "113px",

    "&.Mui-selected, &.Mui-selected:hover": {
        color: "#36454F",
        backgroundColor: "#FF8A80",
    },
    "&:hover": { backgroundColor: "#E5E4E2" },

    "@media (width >= 765px)": {
        width: "95px",
    },

    "@media(width >= 1200px)": {
        width: "120px",
    },
});

const ButtonGroup = styled(ToggleButtonGroup)({
    display: "flex",
    flexDirection: "row",
    borderRadius: "10px",
    height: "55px",
    width: "335px",
    boxShadow:
        "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",

    "@media(width >= 768px)": {
        width: "280px",
        height: "45px",
    },

    "@media(width >= 1200px)": {
        width: "350px",
    },
});

const Submit = styled(Button)({
    borderRadius: "50%",
    width: "50px",
    height: "65px",
    padding: "0.5rem",
    alignSelf: "center",
    color: "white",
    boxShadow:
        "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
    background:
        "linear-gradient(to bottom, #fdaaaa, #fda2a2, #fc9999, #fc9191, #fb8888, #f98282, #f87d7d, #f67777, #f47473, #f27070, #f06d6c, #ee6969)",
});

const TextareaSection = styled(Stack)({
    flexDirection: "row",
    height: "125px",
    width: "100%",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center",

    "@media(width >= 990px)": {
        gap: "3rem",
        width: "30%",
    },

    "@media(width >= 1900px)": {
        width: "35%",
    },
});

const Textarea = styled(Stack)({
    borderRadius: "10px",
    height: "100%",
    width: "100%",
    backgroundColor: "white",

    "& fieldset": { border: "none" },

    "@media(min-width: 768px)": {
        width: "50%",
    },

    "@media(min-width: 990px)": {
        width: "100%",
    },
});
