import {
    CardContent,
    Grid,
    Typography,
    Button,
    Alert,
    Paper,
    Stack,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { Modal } from "../common/components/Modal";
import { useNavigate } from "react-router-dom";
import fleche2 from "../assets/fleche2.png";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { RouteApp } from "../common/model/RouteApp";
import {
    DisponibiliteStructure,
    ProfessionnelInterestedByStructure,
} from "../structures/model/StructureModel";
import "./StructureCard.css";
import { Item, ItemNight, refStatus } from "./WorkerCard";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import React from "react";
import { useAppDispatch } from "../common/redux/reduxHooks";
import { deleteProject } from "../structures/redux/projectAction";
import { SmartAcceptedCard } from "../common/components/SmartAcceptedCard";

export const ManageStructureCard: FunctionComponent<DisponibiliteStructure> = (
    props: DisponibiliteStructure
) => {
    const [smartAccepted, setSmartAccepted] =
        useState<ProfessionnelInterestedByStructure>();
    const [isDisplayPopupDeleteProject, setisDisplayPopupDeleteProject] =
        React.useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const personnelValidate = props.interested?.filter(
            (v: ProfessionnelInterestedByStructure) =>
                v.status === refStatus.accepted
        );
        if (personnelValidate) {
            setSmartAccepted(personnelValidate[0]);
        }
    }, [props.interested]);

    const deleteDispo = () => {
        setisDisplayPopupDeleteProject(false);
        dispatch(deleteProject(props.id))
            .then(() => {
                navigate(RouteApp.MY_PROJECTS);
            })
            .catch((error: any) => {
                console.error("Failed to delete project:", error);
            });
    };

    const { t } = useTranslation();
    const navigate = useNavigate();

    function capitalizeFirstLetter(string: string) {
        if (string) {
            return string[0].toUpperCase() + string.slice(1);
        }
    }

    return (
        <CardContainer>
            <StyledStack>
                <Title>
                    {t("manageCards.title")} {props.ville}
                </Title>
                <Grid sx={{ width: "100%" }}>
                    <Typography
                        gutterBottom
                        noWrap={true}
                        sx={{
                            fontWeight: "bold",
                            width: "100%",
                            marginLeft: "0.5rem",
                        }}
                    >
                        {t("manageCards.city")} {props.ville}
                    </Typography>
                    <Typography
                        gutterBottom
                        sx={{
                            fontWeight: "bold",
                            width: "100%",
                            marginLeft: "0.5rem",
                        }}
                    >
                        {t("manageCards.profession")} {t(`${props.profession}`)}
                    </Typography>
                </Grid>
                <Typography>{t("manageCards.period")}</Typography>
                <Grid
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="body2"
                        sx={{
                            backgroundColor: "#36454F",
                            color: "white",
                            p: 1,
                            borderRadius: "5px",
                        }}
                    >
                        {new Date(props.dateDemarrage).toLocaleDateString(
                            "fr-FR"
                        )}
                    </Typography>
                    <div>
                        <img
                            src={fleche2}
                            alt="Arrow"
                            style={{
                                width: "30px",
                                height: "30px",
                                zIndex: "10",
                                margin: 0.2,
                            }}
                        ></img>
                    </div>
                    <Typography
                        gutterBottom
                        variant="body2"
                        sx={{
                            backgroundColor: "#36454F",
                            color: "white",
                            p: 1,
                            borderRadius: "5px",
                        }}
                    >
                        {props.dateFin
                            ? new Date(props.dateFin).toLocaleDateString(
                                  "fr-FR"
                              )
                            : t("select.indifferent")}
                    </Typography>
                </Grid>
                <Typography>Selection</Typography>
                <Grid
                    container
                    mb={1}
                    spacing={1}
                    columns={16}
                    sx={{
                        justifyContent: "center",
                        gap: "0.5rem",
                    }}
                    flexWrap={"nowrap"}
                >
                    {props.selection &&
                        props.selection.map((e) => {
                            return e !== t("select.night") ? (
                                <Item>{capitalizeFirstLetter(e)}</Item>
                            ) : (
                                <ItemNight>
                                    {capitalizeFirstLetter(e)}
                                </ItemNight>
                            );
                        })}
                </Grid>
            </StyledStack>
            <CardContent>
                <Description>Description : {props.description}</Description>
                {!props.enabled && (
                    <Alert
                        color="error"
                        sx={{
                            width: "100%",
                        }}
                    >
                        {t("project.validation")}
                    </Alert>
                )}
                {props.enabled && (
                    <div
                        style={{
                            width: "100%",
                            padding: "0.75rem",
                        }}
                    >
                        <Typography sx={{ fontSize: "14px" }}>
                            {props.interested?.length}{" "}
                            {t("project.details.smart")}
                        </Typography>
                        <Paper>
                            {smartAccepted && (
                                <SmartAcceptedCard
                                    Professionnel={smartAccepted.Professionnel}
                                    status="ACCEPTED"
                                ></SmartAcceptedCard>
                            )}
                        </Paper>
                    </div>
                )}

                <Delete
                    color="error"
                    onClick={() => setisDisplayPopupDeleteProject(true)}
                    variant="contained"
                    size="large"
                    endIcon={<HighlightOffIcon />}
                >
                    {t("button.delete.project")}
                </Delete>
                <Modal
                    open={isDisplayPopupDeleteProject}
                    message={" "}
                    onCancel={() => setisDisplayPopupDeleteProject(false)}
                    titleButtonCancel={t("button.modal.cancel")}
                    title={t("project.delete.confirmation")}
                    onClose={() => setisDisplayPopupDeleteProject(false)}
                    onValidation={() => deleteDispo()}
                    titleButtonValidation={t("button.modal.valid")}
                    showBackground={false}
                ></Modal>
            </CardContent>
        </CardContainer>
    );
};

const CardContainer = styled(Paper)({
    marginTop: "1rem",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAF9F6",
    width: "95dvw",
    height: "auto",
    boxShadow:
        "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",

    "@media(width >= 768px)": {
        width: "50dvw",
    },

    "@media (width >= 990px)": {
        width: "30dvw",
        padding: "1rem",
    },
});

const StyledStack = styled(Stack)({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    alignItems: "center",
    height: "100%",
});

const Description = styled(Typography)({
    overflow: "auto",
    height: "100px",
    marginBottom: "1rem",
    textAlign: "start",
    width: "100%",
});

const Delete = styled(Button)({
    width: "100%",
    marginTop: "1rem",
    padding: "0.4rem",

    "@media(width >= 990px)": {},
});

const Title = styled(Typography)({
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
    marginTop: "1rem",
    textDecoration: "underline",
});
