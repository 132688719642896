import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import AwaitingEvent from "../assets/AwaitingEvent.png";
import ValidatedEvent from "../assets/ValidatedEvent.png";
import { formatTime } from "../utils/FormatUtils";

interface EventDialogProps {
    selectedEvent: any;
    isDialogOpen: boolean;
    handleClose: () => void;
}

const EventDialog = ({
    selectedEvent,
    isDialogOpen,
    handleClose,
}: EventDialogProps) => {
    const { t } = useTranslation();

    const formatDate = (date: Date) => {
        return new Date(date).toLocaleDateString("fr-FR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
        });
    };

    return (
        <Dialog open={isDialogOpen} onClose={handleClose}>
            {selectedEvent && (
                <>
                    <DialogTitle>
                        {t("manageCards.title")} {selectedEvent.title}
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <p>
                                <strong>{t("manageCards.city")}</strong>{" "}
                                {selectedEvent.title}
                            </p>
                            <p>
                                <strong>{t("manageCards.profession")}</strong>{" "}
                                {selectedEvent.profession}
                            </p>
                            <p>
                                <strong>{t("project.date.start")}</strong>{" "}
                                {formatDate(selectedEvent.start)}{" "}
                                {formatTime(selectedEvent.hourStart)}
                            </p>
                            <p>
                                <strong>{t("project.date.end")}</strong>{" "}
                                {formatDate(selectedEvent.end)}{" "}
                                {formatTime(selectedEvent.hourEnd)}
                            </p>
                            <p>
                                <strong>{t("manageCards.selection")}</strong>{" "}
                                {selectedEvent.selection.join(", ")}
                            </p>
                            <p>
                                <strong>{t("manageCards.description")}</strong>{" "}
                                {selectedEvent.description}
                            </p>
                        </div>
                        {selectedEvent.enabled ? (
                            <ValidationBox>
                                <ValidationText>
                                    {t("project.calendar.validated")}
                                </ValidationText>
                                <img
                                    src={ValidatedEvent}
                                    alt="Validated Event"
                                    style={{
                                        width: "15px",
                                        height: "15px",
                                        marginRight: "10px",
                                        justifySelf: "end",
                                        textAlign: "right",
                                    }}
                                />
                            </ValidationBox>
                        ) : (
                            <ValidationBox>
                                <ValidationText>
                                    {t("project.calendar.modal.validation")}
                                </ValidationText>
                                <img
                                    src={AwaitingEvent}
                                    alt="Awaiting Event"
                                    style={{
                                        width: "15px",
                                        height: "15px",
                                        marginRight: "10px",
                                        justifySelf: "end",
                                        textAlign: "right",
                                    }}
                                />
                            </ValidationBox>
                        )}
                    </DialogContent>

                    <DialogActions>
                        <StyledLink to="/projects/manage">
                            {t("button.manage.projects")}{" "}
                            <DoubleArrowIcon fontSize="large" />
                        </StyledLink>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

const StyledLink = styled(Link)({
    width: "100%",
    padding: "1rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    color: "#3b3c36",
    textDecoration: "none",
    fontSize: "18px",
    fontWeight: "bold",
    background:
        "linear-gradient(to right top, #c1e1c1, #c2e1c3, #c3e1c5, #c5e1c7, #c6e1c9, #c6e1c9, #c6e1c9, #c6e1c9, #c5e1c7, #c3e1c5, #c2e1c3, #c1e1c1)",
});

const ValidationText = styled("span")({
    fontSize: "16px",
    fontWeight: "bold",
});

const ValidationBox = styled("div")({
    marginTop: "1rem",
    display: "flex",
    width: "100%",
    justifySelf: "center",
    alignItems: "center",
    gap: "0.25rem",
});
export default EventDialog;
