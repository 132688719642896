import { Alert, Box, Button, Paper } from "@mui/material";
import { FormEvent, FunctionComponent, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Modal } from "../common/components/Modal";
import PasswordInput from "../common/components/PasswordInput";
import { RouteApp } from "../common/model/RouteApp";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { postNewPassword } from "./redux/authAction";

export const ResetPasswordPage: FunctionComponent = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const authSelector: any = useAppSelector((state: RootState) => state.auth);

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const token = searchParams.get("token");

        if (data.get("password") && token) {
            dispatch(postNewPassword(data.get("password") as string, token));
        }
    }

    useEffect(() => {
        if (authSelector.isSuccessPasswordConfirm) {
            setIsOpenModal(true);
            setErrorMessage(null);
        } else if (authSelector.isErrorPasswordConfirm) {
            setErrorMessage("Une erreur est survenue");
            setIsOpenModal(false);
        }
    }, [
        authSelector.isSuccessPasswordConfirm,
        authSelector.isErrorPasswordConfirm,
    ]);

    return (
        <Paper
            elevation={12}
            sx={{
                ml: "auto",
                mr: "auto",
                width: "auto",
                height: "300px",
                textAlign: "center",
            }}
        >
            {/* <CssBaseline /> */}
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "inherit",
                    placeContent: "center",
                }}
            >
                {/* <Avatar sx={{ m: 1, bgcolor: '#3d3d3d' }}>
        <LockOutlinedIcon />
      </Avatar> */}

                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <PasswordInput label="Nouveau mot de passe" />
                    {/* <PasswordInput/> */}
                    <Button
                        type="submit"
                        variant="contained"
                        color="error"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {"Changer mon mot de passe"}
                    </Button>

                    {errorMessage ? (
                        <Alert severity="error" className="errorMessage">
                            {errorMessage}
                        </Alert>
                    ) : null}
                </Box>
            </Box>
            <Modal
                open={isOpenModal}
                message={"Votre mot de passe a bien été modifié"}
                title={"Confirmation de changement de mot de passe"}
                onClose={() => {
                    setIsOpenModal(false);
                    setErrorMessage(null);
                }}
                onValidation={() => {
                    setIsOpenModal(false);
                    setErrorMessage(null);
                    navigate(RouteApp.LOGIN);
                }}
                titleButtonValidation={"Valider"}
            ></Modal>
        </Paper>
    );
};
