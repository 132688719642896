import { CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import HandShakeIcon from "../assets/handshakeWhite.png";
import Rejected from "../assets/Rejected.svg";
import { Modal } from "../common/components/Modal";
import { RouteApp } from "../common/model/RouteApp";
import { useAppDispatch } from "../common/redux/reduxHooks";
import {
    DisponibiliteStructure,
    emptyUserStructure,
} from "../search/model/Disponibilite";
import { postSmart } from "../search/redux/searchAction";
import { getEmptyAvatarProfessionnel } from "../utils/FormatUtils";
import { useTranslation } from "react-i18next";

const green = "linear-gradient(to right, #58b2a6, #58b2a6)";
const nightGradient = "linear-gradient(to right, #003366, #090254)";
const greenGradient = "#2F4F4F";
export const Item = styled(Typography)(() => ({
    background: greenGradient,
    boxShadow:
        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
    padding: 8,
    margin: 3,
    borderRadius: "5px",
    color: "white",
    display: "flex",
    justifyContent: "center",
    fontSize: "14px",
    width: "100px",
}));

export const ItemValidated = styled(Typography)(() => ({
    backgroundColor: "#58b2a6",
    background: green,
    padding: 15,
    margin: 3,
    borderRadius: "10px",
    color: "white",
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    fontSize: "17px",
    width: "auto",
    maxWidth: "fit-content",
    wordWrap: "break-word",
}));

export const ItemNight = styled(Typography)(() => ({
    backgroundColor: "#B95757",
    background: nightGradient,
    padding: 10,
    margin: 3,
    borderRadius: "10px",
    color: "white",
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    fontSize: "16px",
    width: "90px",
    maxWidth: "fit-content",
    wordWrap: "break-word",
}));

export const refStatus = {
    accepted: "APPROUVED",
    refused: "REFUSED",
    wait: "WAIT",
};

export const defaultStructureProps: DisponibiliteStructure = {
    account: emptyUserStructure,
    dateDemarrage: new Date(),
    dateFin: new Date(),
    description: "",
    selection: ["", ""],
    enabled: false,
    id: "",
    isInterested: null,
    isMock: true,
    ville: "",
    profession: [],
};

export const WorkerCardStructure: FunctionComponent<DisponibiliteStructure> = (
    props: DisponibiliteStructure
) => {
    const [isDisplayPopupSmart, setIsDisplayPopupSmart] =
        useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    function capitalizeFirstLetter(string: string) {
        if (string) {
            return string[0].toUpperCase() + string.slice(1);
        }
    }

    const smart = () => {
        dispatch(postSmart({ id: props.id, role: "ROLE_PROFESSIONNEL" })).then(
            (res: any) => {
                if (!res.error) {
                    setIsDisplayPopupSmart(true);
                }
            }
        );
    };

    const onValidatePopUpSmart = () => {
        setIsDisplayPopupSmart(false);
        navigate(RouteApp.STRUCTURES);
    };

    let config = {
        color: "#58b2a6",
        message: t("message.smart"),
        logo: true,
        logoIcon: HandShakeIcon,
    };

    if (props.isInterested) {
        if (props.isInterested.status === refStatus.wait) {
            config = {
                color: "#97be5a",
                message: t("message.send.smart"),
                logo: false,
                logoIcon: HandShakeIcon,
            };
        } else if (props.isInterested.status === refStatus.accepted) {
            config = {
                color: "#58b2a6",
                message: t("message.accepted.smart"),
                logo: false,
                logoIcon: HandShakeIcon,
            };
        } else if (props.isInterested.status === refStatus.refused) {
            config = {
                color: "#FF3131",
                message: t("message.refused.smart"),
                logo: false,
                logoIcon: Rejected,
            };
        }
    }
    if (props.fulfilled) {
        config = {
            color: "#58b2a6",
            message: t("message.provided.smart"),
            logo: false,
            logoIcon: HandShakeIcon,
        };
    }

    return (
        <>
            <Container elevation={5}>
                <CardActionArea disabled={!config.logo}>
                    <CardHeader
                        sx={{
                            display: "flex",

                            alignItems: "start",
                            justifyContent: "start",
                        }}
                        subheaderTypographyProps={{ paragraph: true }}
                        avatar={
                            <img
                                src={
                                    props.profilImageUrl ??
                                    getEmptyAvatarProfessionnel()
                                }
                                alt="Profile"
                                style={{
                                    width: 90,
                                    borderRadius: "50%",
                                    height: 90,
                                    objectFit: "cover",
                                    alignSelf: "start",
                                    justifySelf: "start",
                                }}
                            />
                        }
                        title={
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.25rem",
                                }}
                            >
                                <div>
                                    <StyledTypography variant="body2">
                                        {props.ville}
                                    </StyledTypography>
                                </div>
                                <div>
                                    <StyledTypography variant="body2">
                                        {props.profession}
                                    </StyledTypography>
                                </div>
                            </div>
                        }
                        subheader={
                            <Description>
                                <Typography variant="body2">
                                    {props.description}
                                </Typography>
                            </Description>
                        }
                    />
                    <CardContent
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                        }}
                    >
                        <Selection>
                            {props.selection &&
                                props.selection.map((e, i) => {
                                    return (
                                        <div
                                            key={i}
                                            style={{
                                                alignSelf: "center",
                                            }}
                                        >
                                            {e !== t("select.night") ? (
                                                <Item>
                                                    {capitalizeFirstLetter(e)}
                                                </Item>
                                            ) : (
                                                <ItemNight>
                                                    {capitalizeFirstLetter(e)}
                                                </ItemNight>
                                            )}
                                        </div>
                                    );
                                })}
                        </Selection>
                    </CardContent>
                    <Footer>
                        <Grid
                            container
                            columns={16}
                            bgcolor={"#3d3d3d"}
                            textAlign={"center"}
                            pt={1}
                            pb={1}
                        >
                            <Grid
                                item
                                xs={7.9}
                                sx={{ borderRight: "0.1em solid white" }}
                            >
                                <Typography
                                    color={"white"}
                                    sx={{ fontWeight: "bold" }}
                                >
                                    {new Date(
                                        props.dateDemarrage
                                    ).toLocaleDateString("fr")}
                                </Typography>
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={7.9}>
                                <Typography
                                    color={"white"}
                                    sx={{ fontWeight: "bold" }}
                                >
                                    {props.dateFin
                                        ? new Date(
                                              props.dateFin
                                          ).toLocaleDateString("fr")
                                        : t("select.date.undetermined")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            columns={16}
                            bgcolor={config.color}
                            textAlign={"center"}
                            pt={1}
                            pb={1}
                            onClick={() => smart()}
                        >
                            <Grid item xs={15.8}>
                                <Typography
                                    variant="h6"
                                    color={"white"}
                                    sx={{
                                        fontWeight: "bold",
                                        justifyContent: "space-between",
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {config.message}{" "}
                                    {config.logo && (
                                        <img
                                            src={config.logoIcon}
                                            alt="Handshake Smart Santé"
                                            style={{
                                                height: "20%",
                                                width: "20%",
                                                marginLeft: "10px",
                                            }}
                                        ></img>
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Footer>
                </CardActionArea>
            </Container>
            {props.isInterested === null && (
                <Modal
                    open={isDisplayPopupSmart}
                    title={t("message.congratulations.smart")}
                    onClose={() => setIsDisplayPopupSmart(false)}
                    onValidation={() => onValidatePopUpSmart()}
                    titleButtonValidation={t("button.modal.valid")}
                ></Modal>
            )}
        </>
    );
};

const Container = styled(Card)({
    margin: "1rem",
    borderRadius: "15px",
    display: "inline-flex",
    width: "340px",
    height: "440px",
    alignItems: "start",
    boxShadow:
        "rgba(0, 0, 0, 0.3) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px",

    ":hover": {
        boxShadow: 20,
    },
});

const Description = styled("div")({
    display: "flex",
    height: "140px",
    overflow: "auto",
    marginTop: "0.25rem",
});

const StyledTypography = styled(Typography)({
    fontSize: "16px !important",
    fontWeight: "bold !important",
});

const Footer = styled("div")({
    display: "flex",
    justifySelf: "end",
    alignSelf: "end",
    width: "100%",
    marginTop: "1.25rem",
    flexDirection: "column",
});

const Selection = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1rem",
});
