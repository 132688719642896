import styled from "@emotion/styled";
import {
    ProfessionalAvailability,
    StructureAvailability,
} from "./model/ChatbotResponse";
import {
    CalendarMonthOutlined,
    CheckCircleOutline,
    HandshakeOutlined,
    PersonOutline,
    PinDropOutlined,
    TimerOutlined,
} from "@mui/icons-material";
import { t } from "i18next";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { postSmart } from "../search/redux/searchAction";
import { useState } from "react";
import { RootState } from "../common/redux/store";

type SmartStatus = "notSent" | "sending" | "sent" | "error";

export interface AvailabilityCardProps {
    availability: StructureAvailability | ProfessionalAvailability;
}

const formatAvailability = (
    availability: StructureAvailability | ProfessionalAvailability
) => ({
    name:
        "prenom" in availability && "nom" in availability
            ? `${availability.prenom} ${availability.nom.slice(0, 1)}.`
            : null,
    jobs: Array.isArray(availability.profession)
        ? availability.profession
        : [availability.profession],
    departments:
        "departements" in availability
            ? availability.departements
            : [availability.departement],
    city: "ville" in availability ? availability.ville : null,
    startDate: new Date(availability.dateDemarrage.date),
    endDate: new Date(availability.dateFin.date),
    criteria: availability.selection,
});

const AvailabilityCard: React.FC<AvailabilityCardProps> = ({
    availability,
}) => {
    const [smartStatus, setSmartStatus] = useState<SmartStatus>("notSent");

    const dispatch = useAppDispatch();
    const userSelector = useAppSelector((state: RootState) => state.user.data);
    const formattedAvailability = formatAvailability(availability);

    const sendSmart = () => {
        const role = userSelector.societe
            ? "ROLE_STRUCTURE"
            : "ROLE_PROFESSIONNEL";
        setSmartStatus("sending");
        dispatch(postSmart({ id: availability.id, role }))
            .then((res: any) => {
                if (!res.error) {
                    setSmartStatus("sent");
                    return;
                }

                setSmartStatus("error");
            })
            .catch(() => {
                setSmartStatus("error");
            });
    };

    const renderSmartButton = () => {
        switch (smartStatus) {
            case "notSent":
                return (
                    <SmartButton onClick={sendSmart}>
                        <HandshakeOutlined />
                        {t("message.smart")}
                    </SmartButton>
                );
            case "sending":
                return (
                    <SmartSending>{t("message.sending.smart")}</SmartSending>
                );
            case "sent":
                return (
                    <SmartSent>
                        <CheckCircleOutline />
                        {t("message.send.smart")}
                    </SmartSent>
                );
            case "error":
                return (
                    <>
                        <SmartButton onClick={sendSmart}>
                            <HandshakeOutlined />
                            {t("message.smart")}
                        </SmartButton>
                        <SmartError>{t("message.error.smart")}</SmartError>
                    </>
                );
        }
    };

    return (
        <Container>
            <Title>{formattedAvailability.jobs.join(", ")}</Title>
            {formattedAvailability.name && (
                <Information>
                    <PersonOutline />
                    <p>{formattedAvailability.name}</p>
                </Information>
            )}
            {formattedAvailability.city ? (
                <Information>
                    <PinDropOutlined />
                    <p>
                        {formattedAvailability.city},{" "}
                        {formattedAvailability.departments[0]}
                    </p>
                </Information>
            ) : (
                <Information>
                    <PinDropOutlined />
                    <p>{formattedAvailability.departments.join(", ")}</p>
                </Information>
            )}

            <Information>
                <CalendarMonthOutlined />
                <p>
                    {formattedAvailability.startDate.toLocaleDateString()} -{" "}
                    {formattedAvailability.endDate.toLocaleDateString()}
                </p>
            </Information>
            <TagsContainer>
                <TimerOutlined />
                {formattedAvailability.criteria.map((criteria, index) => (
                    <Tag key={index}>{criteria}</Tag>
                ))}
            </TagsContainer>
            {renderSmartButton()}
        </Container>
    );
};

export default AvailabilityCard;

const Container = styled("div")({
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    border: "1px solid #e0e0e0",
    borderRadius: "5px",
    marginBottom: "10px",
    backgroundColor: "#f9f9f9",
});

const Title = styled("p")({
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "5px",
});

const Information = styled("div")({
    fontSize: "12px",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
    gap: "5px",
});

const TagsContainer = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    marginBottom: "10px",
});

const Tag = styled("p")({
    padding: "5px",
    backgroundColor: "#e0e0e0",
    borderRadius: "5px",
    fontSize: "12px",
});

const SmartButton = styled("button")({
    backgroundColor: "#E53935",
    color: "#fff",
    "&:active": {
        opacity: 0.5,
    },
    borderRadius: "5px",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    gap: "5px",
});

const SmartSending = styled("p")({
    fontSize: "12px",
    color: "gray",
    textAlign: "center",
    marginTop: "10px",
    opacity: 0.5,
});

const SmartSent = styled("p")({
    fontSize: "12px",
    color: "#4CAF50",
    textAlign: "center",
    marginTop: "10px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
});

const SmartError = styled("p")({
    fontSize: "12px",
    color: "#E53935",
    textAlign: "center",
    marginTop: "10px",
});
