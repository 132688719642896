import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {
    getEmptyAvatarProfessionnel,
    getPictureFromProfilImage,
} from "../../utils/FormatUtils";
import { ItemValidated } from "../../cards/WorkerCard";
import { ProfessionnelInterestedByStructure } from "../../structures/model/StructureModel";
import { StructureInterestedByProfessionnel } from "../../professionnels/model/ProfessionnelModel";

interface SmartAcceptedCardProps {
    Professionnel?: ProfessionnelInterestedByStructure["Professionnel"];
    Structure?: StructureInterestedByProfessionnel["Structure"];
    status: string;
}

export const SmartAcceptedCard: React.FC<SmartAcceptedCardProps> = (props) => {
    const { Professionnel, Structure, status } = props;

    return (
        <Grid item xs={12} md={6}>
            <Card sx={{ display: "flex" }}>
                <CardContent sx={{ flex: 1 }}>
                    {Professionnel && (
                        <>
                            <Typography component="h4" variant="h5">
                                {Professionnel.nom}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                color="text.secondary"
                            >
                                {Professionnel.prenom}
                            </Typography>
                            {Professionnel.profession.map((v, i) => (
                                <Typography variant="subtitle1" key={i}>
                                    {v}
                                </Typography>
                            ))}
                            <Typography variant="subtitle1" color="primary">
                                {Professionnel.telephone}
                            </Typography>
                        </>
                    )}

                    {Structure && (
                        <>
                            <Typography component="h2" variant="h5">
                                {Structure.societe}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                color="text.secondary"
                            >
                                {Structure.ville}
                            </Typography>
                            <Typography variant="subtitle1" color="primary">
                                {Structure.telephone}
                            </Typography>
                        </>
                    )}

                    <ItemValidated sx={{ ml: "auto" }}>
                        {status} Validé !
                    </ItemValidated>
                </CardContent>

                <CardMedia
                    component="img"
                    sx={{ width: 120, display: { xs: "none", sm: "block" } }}
                    image={
                        Professionnel?.profileImage
                            ? getPictureFromProfilImage(
                                  Professionnel.profileImage.contentUrl
                              )
                            : getEmptyAvatarProfessionnel()
                    }
                    alt={"profileImage Smart Sante"}
                />
            </Card>
        </Grid>
    );
};
