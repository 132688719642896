import { DisponibiliteProfessionnel } from "../../search/model/Disponibilite";
import { UserStructureResponse } from "../../structures/model/StructureModel";

export interface UserProfessionnelResponse {
    nom: string;
    prenom: string;
    profession: string[];
    disponibilities: DisponibiliteProfessionnel[];
    id: string;
    email: string;
    roles: string[];
    enabled: boolean;
    profile_image: string;
    profileImage: AvatarResponse;
    description: string;
    telephone: string;
}

export interface CreateDisponibiliteProfessionnel {
    departements: string[];
    profession: string[];
    selection: string[];
    description: string;
    type: string;
    dateDemarrage: string | null;
    dateFin?: string | null;
    heureDemarrage: string | null;
    heureFin: string | null;
}

export interface StructureInterestedByProfessionnel {
    Structure: UserStructureResponse;
    status: string;
}

export const emptyUserProfessionnel: UserProfessionnelResponse = {
    nom: "",
    prenom: "",
    profession: [],
    disponibilities: [],
    id: "",
    email: "",
    roles: [],
    profile_image: "",
    enabled: false,
    telephone: "",
    profileImage: {
        id: "",
        contentUrl: "",
    },
    description: "",
};

export interface AvatarResponse {
    id: string;
    contentUrl: string;
}
