import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EmptyCard } from "../cards/EmptyCard";
import { WorkerCard } from "../cards/WorkerCard";
import { RouteApp } from "../common/model/RouteApp";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import {
    getInterestProfessionnels,
    getInterestStructure,
} from "../professionnels/redux/professionnelAction";
import { DisponibiliteProfessionnel } from "../search/model/Disponibilite";
import {
    DisponibiliteStructure,
    UserStructureResponse,
} from "../structures/model/StructureModel";
import { language, LanguageType } from "../utils/LanguageUtils";

import "./DashboardPage.css";
import styled from "@emotion/styled";
import { ManageStructureCard } from "../cards/ManageStructureCard";
import { ManageProfessionnelCard } from "../cards/ManageProfessionnelCard";
import { StructureCard } from "../cards/StructureCard";

export const Dashboard = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [, setPersonnelMatched] = React.useState<
        DisponibiliteProfessionnel[] | []
    >([]);
    const [, setStructureMatched] = React.useState<
        DisponibiliteStructure[] | []
    >([]);

    const structuresMatchedSelector = useAppSelector(
        (state: RootState) => state.structure
    );

    const professionnelMatchedSelector = useAppSelector(
        (state: RootState) => state.professionnel
    );
    const userSelector: UserStructureResponse = useAppSelector(
        (state: RootState) => state.user.data
    );

    const userProData = useAppSelector(
        (state: RootState) => state.userPro.data
    );

    const idUser = useAppSelector((state: RootState) => state.user.data.id);
    const idUserProfessionnel = useAppSelector(
        (state: RootState) => state.userPro.data.id
    );
    const dispatch = useAppDispatch();
    const lang: LanguageType = language();
    const { t } = useTranslation();

    const isMobile = useMediaQuery("(max-width:990px)");

    useEffect(() => {
        dispatch(getInterestStructure(idUserProfessionnel));
        dispatch(getInterestProfessionnels(idUser));
    }, [dispatch, idUser, idUserProfessionnel]);

    useEffect(() => {
        if (professionnelMatchedSelector.data.length > 0) {
            setPersonnelMatched(professionnelMatchedSelector.data);
        } else if (structuresMatchedSelector.data.length > 0) {
            setStructureMatched(structuresMatchedSelector.data);
        }
    }, [professionnelMatchedSelector.data, structuresMatchedSelector.data]);

    const count = useMemo(() => {
        if (professionnelMatchedSelector.data.length > 0) {
            return professionnelMatchedSelector.data.length;
        } else if (structuresMatchedSelector.data.length > 0) {
            return structuresMatchedSelector.data.length;
        }
        return 0;
    }, [professionnelMatchedSelector.data, structuresMatchedSelector.data]);

    const personnelsCalled = (props: DisponibiliteProfessionnel, i: number) => {
        return (
            <Grid item key={i} m={1} lg={6}>
                <WorkerCard {...props} key={i} />
            </Grid>
        );
    };

    const companyCalled = (props: DisponibiliteStructure, i: number) => {
        return (
            <Grid item key={i} m={1} lg={6}>
                <StructureCard {...props} key={i} />
            </Grid>
        );
    };

    const structureCalled = (props: DisponibiliteStructure, i: number) => {
        return (
            <Grid item card={6} key={i} lg={9} xs={7}>
                <ManageStructureCard
                    {...props}
                    indexOfDisponibility={i}
                    key={i}
                />
            </Grid>
        );
    };

    const professionnelCalled = (
        props: DisponibiliteProfessionnel,
        i: number
    ) => {
        return (
            <Grid item card={6} key={i} lg={9} xs={7}>
                <ManageProfessionnelCard
                    {...props}
                    indexOfDisponibility={i}
                    key={i}
                />
            </Grid>
        );
    };

    return (
        <Stack
            sx={{
                width: "100%",
            }}
        >
            <ContactContainer
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    mt: 4,
                }}
            >
                <Box
                    sx={{
                        width: "fit-content",
                        gridArea: "card",
                    }}
                >
                    {userSelector.id && userSelector.societe ? (
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                ml: 2,
                                mt: 3,
                            }}
                        >
                            {t("dashboard.personal.contacted")}
                            <span
                                style={{
                                    backgroundColor: "#3d3d3d",
                                    padding: 6,
                                    color: "white",
                                    borderRadius: "50%",
                                    width: "30px",
                                    height: "30px",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    marginLeft: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {count}
                            </span>
                        </Typography>
                    ) : (
                        <Typography
                            variant="h6"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                ml: 2,
                                mt: 3,
                            }}
                        >
                            {t("dashboard.personal.structure")}
                            <span
                                style={{
                                    backgroundColor: "#3d3d3d",
                                    padding: 6,
                                    color: "white",
                                    borderRadius: "50%",
                                    width: "30px",
                                    height: "30px",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    marginLeft: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {count}
                            </span>
                        </Typography>
                    )}
                    {userSelector.id && userSelector.societe ? (
                        <ContactCard>
                            {professionnelMatchedSelector.data
                                .filter((_item: any, idx: number) => idx < 3)
                                .map(
                                    (
                                        item: DisponibiliteProfessionnel,
                                        i: number
                                    ) => {
                                        if (item.id.length > 1) {
                                            return personnelsCalled(item, i);
                                        }
                                        return null;
                                    }
                                )}
                            {professionnelMatchedSelector.length <= 2 && (
                                <EmptyCard
                                    message={lang.addPersonnel}
                                    component={RouteApp.SEARCH}
                                ></EmptyCard>
                            )}
                        </ContactCard>
                    ) : (
                        <ContactCard>
                            {structuresMatchedSelector.data
                                .filter((_item: any, idx: number) => idx < 3)
                                .map(
                                    (
                                        item: DisponibiliteStructure,
                                        i: number
                                    ) => {
                                        if (item.id && item.id.length > 1) {
                                            return companyCalled(item, i);
                                        }
                                        return null;
                                    }
                                )}
                            {structuresMatchedSelector.length < 3 && (
                                <EmptyCard
                                    message={lang.addStructure}
                                    component={RouteApp.SEARCH}
                                />
                            )}
                        </ContactCard>
                    )}
                </Box>
            </ContactContainer>

            <ProjectContainer>
                <Project>
                    <Stack
                        sx={{
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        {userSelector.id && userSelector.societe ? (
                            <Typography
                                variant="h6"
                                sx={{
                                    display: "flex",
                                    mt: 3,
                                    ml: 2,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {t("dashboard.projects")}
                                <span
                                    style={{
                                        backgroundColor: "#3d3d3d",
                                        padding: 6,
                                        color: "white",
                                        borderRadius: "50%",
                                        width: "30px",
                                        height: "30px",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        marginLeft: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {userSelector.disponibilities.length}
                                </span>
                            </Typography>
                        ) : (
                            <Typography
                                variant="h6"
                                sx={{
                                    display: "flex",
                                    mt: 3,
                                    ml: 2,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {t("dashboard.structure")}
                                <span
                                    style={{
                                        backgroundColor: "#3d3d3d",
                                        padding: 6,
                                        color: "white",
                                        borderRadius: "50%",
                                        width: "30px",
                                        height: "30px",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        marginLeft: "10px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {userProData.disponibilities.length}
                                </span>
                            </Typography>
                        )}
                    </Stack>
                    <Cards>
                        {userSelector.societe
                            ? isMobile
                                ? userSelector.disponibilities.map((item, i) =>
                                      structureCalled(item, i)
                                  )
                                : userSelector.disponibilities
                                      .slice(
                                          currentIndex === 0 ? 0 : 3,
                                          currentIndex === 0 ? 3 : 4
                                      )
                                      .map((item, i) =>
                                          structureCalled(item, i)
                                      )
                            : isMobile
                            ? userProData.disponibilities.map(
                                  (
                                      item: DisponibiliteProfessionnel,
                                      i: number
                                  ) => professionnelCalled(item, i)
                              )
                            : userProData.disponibilities
                                  .slice(
                                      currentIndex === 0 ? 0 : 3,
                                      currentIndex === 0 ? 3 : 4
                                  )
                                  .map(
                                      (
                                          item: DisponibiliteProfessionnel,
                                          i: number
                                      ) => professionnelCalled(item, i)
                                  )}

                        {userSelector.societe
                            ? userSelector.disponibilities.length <= 2 && (
                                  <EmptyCard
                                      message={lang.addProject}
                                      component={RouteApp.MY_PROJECTS}
                                  />
                              )
                            : userProData.disponibilities.length <= 2 && (
                                  <EmptyCard
                                      message={lang.addProject}
                                      component={RouteApp.MY_PROJECTS}
                                  />
                              )}
                    </Cards>
                </Project>
            </ProjectContainer>
        </Stack>
    );
};

const ContactContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
    margin: "4rem 0 4rem 0",

    "@media(width >= 990px)": {
        alignItems: "start",
    },
});

const ContactCard = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",

    "@media(width >= 990px)": {
        flexDirection: "row",
        marginLeft: "0.5rem",
    },
});

const ProjectContainer = styled(Box)({
    display: "flex",
    marginTop: "1rem",
    background: "#DCDCDC",
    width: "100%",
    gap: 5,
    margin: "0 0 4rem 0",
    padding: 0,
});

const Project = styled(Box)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    "@media(width >= 990px)": {
        alignItems: "start",
        justifyContent: "center",
        width: "fit-content",
    },
});

const Cards = styled(Stack)({
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "3rem",

    "@media(width >= 990px)": {
        flexDirection: "row",
        justifyContent: "start",
        margin: "0 0 1rem 1rem",
    },
});
