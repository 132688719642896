import { useTranslation } from "react-i18next";

export const language = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation();

    return {
        modalConfirmation: t("language.modalConfirmation.description"),
        modalSmart: t("language.modalSmart"),
        modalCrédential: t("forgot.password.description.modal"),
        createProject: t("language.createProject"),
        titleModalSignUpSuccess: t("language.titleModalSignUpSuccess"),
        messageModalSignUpSucces: t("language.messageModalSignUpSucces"),
        addProject: t("language.addProject"),
        addPersonnel: t("language.addPersonnel"),
        addStructure: t("language.addStructure"),
        createProjectValidation: t("language.createProjectValidation"),
        congratulation: t("language.congratulation"),
        waitingValidation: t("language.waitingValidation"),

        navbar: {
            dashboard: t("navbar.dashboard"),
            personnel: t("navbar.personal"),
            contact: t("navbar.contact"),
            projet: t("navbar.projects"),
            profile: "Profil",
            disponibilite: t("navbar.disponibilite"),
            structures: t("navbar.structure"),
        },

        headerLeft: {
            personnel: "Personnels",
            contact: "Contacts",
            projet: "Projets",
            profil: "Profil",
            disponibites: "Disponibilités",
        },
    };
};

export type LanguageType = ReturnType<typeof language>;
