import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    IStateReducer,
    initialStateReducer,
} from "../../common/model/IStateReducer";
import { AxiosError } from "axios";
import {
    ResponseViolations,
    postAvatar,
    signUpProfessionnel,
} from "./signUpAction";
import {
    AvatarResponse,
    emptyUserProfessionnel,
    UserProfessionnelResponse,
} from "../model/ProfessionnelModel";

const initialUserProfessionnel: UserProfessionnelResponse =
    emptyUserProfessionnel;

const initialState: IStateReducer<UserProfessionnelResponse> = {
    ...initialStateReducer,
    data: initialUserProfessionnel,
};

export const signUpSlice = createSlice({
    name: "userProfessionnel",
    initialState,
    reducers: {
        setPicture: (state, action) => {
            state.data.profileImage = action.payload.data;
        },
        clearPicture: (state) => {
            state.data.profileImage = { contentUrl: "", id: "" };
            state.isExtraField = false;
        },
        setSignUpProfessionnel: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(postAvatar.pending, (state) => {
            state.isLoader = true;
            state.isExtraField = true;
            state.isSuccess = false;
            state.error = undefined;
            state.requestType = "POST";
        });
        builder.addCase(
            postAvatar.fulfilled,
            (state, action: PayloadAction<AvatarResponse>) => {
                state.data.profileImage = action.payload;
                state.isExtraField = false;
                state.isLoader = false;
                state.isSuccess = true;
                state.requestType = "POST";
            }
        );
        builder.addCase(
            postAvatar.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoader = false;
                state.isExtraField = false;
                state.isSuccess = false;
                state.error = action.payload as AxiosError<ResponseViolations>;
                state.requestType = "POST";
            }
        );

        builder.addCase(signUpProfessionnel.pending, (state) => {
            state.isLoader = true;
            state.isSuccess = false;
            state.error = undefined;
            state.requestType = "POST";
        });
        builder.addCase(signUpProfessionnel.fulfilled, (state, action) => {
            state.data = action.payload;
            state.isLoader = false;
            state.isSuccess = true;
            state.requestType = "POST";
        });
        builder.addCase(signUpProfessionnel.rejected, (state, action) => {
            state.isLoader = false;
            state.isSuccess = false;
            state.error = action.payload as AxiosError<ResponseViolations>;
            state.requestType = "POST";
        });
    },
});

// Action creators are generated for each case reducer function
export const { clearPicture, setSignUpProfessionnel, setPicture } =
    signUpSlice.actions;

export default signUpSlice;
