export interface User {
    nom: string;
    prenom: string;
    profession: string[];
    id: string;
    email: string;
    roles: string[];
    enabled: boolean;
    profile_image: string;
    description: string;
    telephone: string;
}

export enum Role {
    Professionnel = "ROLE_PROFESSIONNEL",
    Structure = "structure",
    User = "ROLE_USER",
    SuperAdmin = "ROLE_SUPER_ADMIN",
}

export interface Auth {
    token: string;
    roles: [string];
    id: string;
    refresh_token: string;
}
