import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RouteAPI, api } from "../../common/service/apiService";
import { SignUpData } from "../../login/model/SignUpData";
import { UserStructureResponse } from "../model/StructureModel";
import { setPicture, setSignUpStructure } from "./signUpSlice";

interface AvatarResponse {
    contentUrl: string;
    id: string;
}

export interface ResponseViolations {
    detail: string;
    title: string;
    type: string;
    violations: any;
}

// export const postAvatar = (avatar: File) => {
//   return async(dispatch: any, getState: any) => {
//     axios.post('http://localhost:8080' + RouteAPI.UPLOAD_IMAGE, {file: avatar}, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     })
//     .then((response: AxiosResponse<AvatarResponse>) => {
//       const idFile = response.data.id
//       const contentUrl = response.data.contentUrl
//       return {id: idFile, contentUrl: contentUrl}
//     })
//     .catch((error: AxiosError<any>) => {
//       return getState.rejectWithValue(error as AxiosError<ResponseMessage>);
//     })
//   }
// }

export const postAvatar = createAsyncThunk<AvatarResponse, File>(
    "profileImage/upload",
    async (profileImage: File, thunkAPI: any) => {
        try {
            const response: AvatarResponse = await api(
                RouteAPI.UPLOAD_IMAGE,
                "POST",
                { file: profileImage }
            ).then((res) => res.data);
            thunkAPI.dispatch(setPicture(response));
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error as AxiosError<ResponseViolations>
            );
        }
    }
);

export const signUp = createAsyncThunk<UserStructureResponse, SignUpData>(
    "structure/create",
    async (signUpData: SignUpData, thunkAPI: any) => {
        try {
            const response: UserStructureResponse = await api(
                RouteAPI.SIGN_UP_STRUCTURES,
                "POST",
                signUpData
            ).then((res) => res.data);
            thunkAPI.dispatch(setSignUpStructure(response));
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error as AxiosError<ResponseViolations>
            );
        }
    }
);
