import {
    UserStructureResponse,
    emptyUserStructure,
} from "./../../structures/model/StructureModel";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    emptyUserProfessionnel,
    UserProfessionnelResponse,
} from "../model/Disponibilite";
import { getDisponibilities } from "./searchAction";
import {
    initialStateReducer,
    IStateReducer,
} from "../../common/model/IStateReducer";
import { ResponseMessage } from "../../common/model/ResponseMessage";

const initialUsersState: IStateReducer<UserProfessionnelResponse[]> & {
    strucData: UserStructureResponse[];
} = {
    ...initialStateReducer,
    data: [emptyUserProfessionnel],
    strucData: [emptyUserStructure],
};

export const searchSlice = createSlice({
    name: "search",
    initialState: initialUsersState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getDisponibilities.pending, (state) => {
            state.isLoader = true;
            state.isSuccess = false;
            state.error = undefined;
            state.requestType = "GET";
        });
        builder.addCase(getDisponibilities.fulfilled, (state, action) => {
            state.data = action.payload.professionnels;
            state.strucData = action.payload.structures;
            state.isLoader = false;
            state.isSuccess = true;
            state.requestType = "GET";
        });
        builder.addCase(
            getDisponibilities.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoader = false;
                state.isSuccess = false;
                state.error = action.payload as AxiosError<ResponseMessage>;
                state.requestType = "GET";
            }
        );
    },
});

// Action creators are generated for each case reducer function

export default searchSlice;
