import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, useLocation } from "react-router-dom";
import "./App.css";
import MainRoutes from "./common/components/MainRoutes";
import CitiesAvailable from "./utils/Cities.json";
import DepartementsAvailable from "./utils/Departements.json";
import useIpLanguageDetection from "./common/api/useIpLanguageDetection";
import ChatbotComponent from "./chatbot/ChatbotComponent";
import { useAppSelector } from "./common/redux/reduxHooks";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { restoreUserSession } from "./utils/UserSessionUtils";

declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        mobile: true;
        tablet: true;
        laptop: true;
        card: true;
    }
}

const defaultTheme = createTheme();
const theme = createTheme({
    palette: {
        primary: {
            main: "#3d3d3d",
        },
        secondary: {
            main: "#FE807F",
        },
    },
    typography: {
        fontFamily: "Montserrat",
        button: {
            textTransform: "none",
        },
        h4: {
            color: "#3d3d3d",
        },
    },
    breakpoints: {
        values: {
            ...defaultTheme.breakpoints.values,
            mobile: 0,
            tablet: 0,
            laptop: 0,
            card: 1800,
        },
    },
});

export interface CitiesFromGovernment {
    nom: string;
    code: string;
}

export interface DepartementsFromGovernment {
    nom: string;
    code: string;
}

export let Departements: DepartementsFromGovernment[] = DepartementsAvailable;
export let Cities: CitiesFromGovernment[] = CitiesAvailable;

const AppContent = () => {
    const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
    const userRoles = useAppSelector((state) => state.auth.data.roles);

    const location = useLocation();

    const userRole = userRoles.includes("structure")
        ? "structure"
        : "ROLE_USER";

    useEffect(() => {
        restoreUserSession().catch((error) => {
            console.error(error);
        });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <MainRoutes />

            {isLoggedIn && location.pathname !== "/login" && (
                <Stack sx={{ width: "fit-content" }}>
                    <ChatbotComponent isLoggedIn={true} kind={userRole} />
                </Stack>
            )}
        </ThemeProvider>
    );
};

function App() {
    useIpLanguageDetection();

    return (
        <BrowserRouter>
            <AppContent />
        </BrowserRouter>
    );
}

export default App;
