import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    initialStateReducer,
    IStateReducer,
} from "../../common/model/IStateReducer";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { DisponibiliteProfessionnel } from "../../search/model/Disponibilite";
import { defaultProfessionnelProps } from "../../cards/WorkerCard";
import { postUpdateProfil, professionnelUpdateProfil } from "./profilAction";

const initialUsersState: IStateReducer<DisponibiliteProfessionnel[]> = {
    ...initialStateReducer,
    data: [defaultProfessionnelProps],
};
export const profilSlice = createSlice({
    name: "profil",
    initialState: initialUsersState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(postUpdateProfil.pending, (state) => {
            state.isLoader = true;
            state.isSuccess = false;
            state.error = undefined;
            state.requestType = "PATCH";
        });
        builder.addCase(postUpdateProfil.fulfilled, (state, action) => {
            state.data = action.payload;
            state.isLoader = false;
            state.isSuccess = true;
            state.requestType = "PATCH";
        });
        builder.addCase(
            postUpdateProfil.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoader = false;
                state.isSuccess = false;
                state.error = action.payload as AxiosError<ResponseMessage>;
                state.requestType = "PATCH";
            }
        );

        builder.addCase(professionnelUpdateProfil.pending, (state) => {
            state.isLoader = true;
            state.isSuccess = false;
            state.error = undefined;
            state.requestType = "PATCH";
        });
        builder.addCase(
            professionnelUpdateProfil.fulfilled,
            (state, action) => {
                state.data = action.payload;
                state.isLoader = false;
                state.isSuccess = true;
                state.requestType = "PATCH";
            }
        );
        builder.addCase(
            professionnelUpdateProfil.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoader = false;
                state.isSuccess = false;
                state.error = action.payload as AxiosError<ResponseMessage>;
                state.requestType = "PATCH";
            }
        );
    },
});

// Action creators are generated for each case reducer function

export default profilSlice;
