import React from "react";
import { useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { SocieteForm } from "./StructureForm";
import { ProfessionnelForm } from "./ProfessionnelForm";
import styled from "@emotion/styled";

export const ProfilPage = () => {
    const userSelector = useAppSelector((state: RootState) => state.user.data);

    return (
        <Container>
            {userSelector.societe ? <SocieteForm /> : <ProfessionnelForm />}
        </Container>
    );
};

const Container = styled("div")({
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "2rem",
});
