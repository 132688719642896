import { FunctionComponent } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Role } from "../model/User";
import Header from "./Header";
import { Dashboard } from "../../dashboard/DashboardPage";
import { ForgotPage } from "../../login/ForgotPage";
import LoginPage from "../../login/LoginPage";
import { ResetPasswordPage } from "../../login/ResetPasswordPage";
import SignUpPage from "../../login/SignUpPage";
import { ProfessionnelPage } from "../../professionnels/ProfessionnelPage";
import { ProfilPage } from "../../profil/ProfilPage";
import { LegacyPage } from "../../legacy/LegacyPage";
import { SearchPage } from "../../search/SearchPage";
import { ProjectDetailsPage } from "../../structures/ProjectDetailsPage";
import { ProjectPage } from "../../structures/ProjectPage";
import { RouteApp } from "../model/RouteApp";
import { NotFoundPage } from "./NotFoundPage";
import PrivateRoute from "./PrivateRoute";
import { ManageProjectsPage } from "../../projects/ManageProjectsPage";
import Footer from "./Footer";

const MainRoutes: FunctionComponent = () => {
    return (
        <>
            <Header />
            <Routes>
                <>
                    <Route
                        path={"/"}
                        element={<Navigate to={RouteApp.LOGIN} />}
                    ></Route>
                    <Route path={RouteApp.LOGIN} element={<LoginPage />} />
                    <Route path={RouteApp.SIGNUP} element={<SignUpPage />} />
                    <Route
                        path={RouteApp.FORGOT_PASSWORD}
                        element={<ForgotPage />}
                    />
                    <Route
                        path={RouteApp.RESET_PASSWORD}
                        element={<ResetPasswordPage />}
                    />
                </>

                <Route
                    index
                    path={RouteApp.DASHBOARD}
                    element={
                        <PrivateRoute
                            roles={[
                                Role.User,
                                Role.Structure,
                                Role.Professionnel,
                            ]}
                        >
                            <Dashboard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteApp.PROFESSIONNELS}
                    element={
                        <PrivateRoute
                            roles={[
                                Role.User,
                                Role.Structure,
                                Role.Professionnel,
                            ]}
                        >
                            <ProfessionnelPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteApp.SEARCH}
                    element={
                        <PrivateRoute
                            roles={[
                                Role.User,
                                Role.Structure,
                                Role.Professionnel,
                            ]}
                        >
                            <SearchPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteApp.MY_PROJECTS}
                    element={
                        <PrivateRoute
                            roles={[
                                Role.User,
                                Role.Structure,
                                Role.Professionnel,
                            ]}
                        >
                            <ProjectPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={RouteApp.MANAGE_PROJECTS}
                    element={
                        <PrivateRoute
                            roles={[
                                Role.User,
                                Role.Structure,
                                Role.Professionnel,
                            ]}
                        >
                            <ManageProjectsPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={RouteApp.MY_PROJECTS_DETAILS + "/:idProject"}
                    element={
                        <PrivateRoute
                            roles={[
                                Role.User,
                                Role.Structure,
                                Role.Professionnel,
                            ]}
                        >
                            <ProjectDetailsPage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path={RouteApp.MY_ACCOUNT}
                    element={
                        <PrivateRoute
                            roles={[
                                Role.User,
                                Role.Structure,
                                Role.Professionnel,
                            ]}
                        >
                            <ProfilPage />
                        </PrivateRoute>
                    }
                />
                {
                    <Route
                        path={RouteApp.LEGACY}
                        element={<LegacyPage />}
                    ></Route>
                }
                {<Route path="*" element={<NotFoundPage />}></Route>}
            </Routes>
            <Footer />
        </>
    );
};

export default MainRoutes;
