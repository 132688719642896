import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Drawer,
    IconButton,
    List,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../login/redux/authAction";
import {
    AvatarResponse,
    UserStructureResponse,
} from "../../structures/model/StructureModel";
import { getEmptyAvatarStructure } from "../../utils/FormatUtils";
import { RouteUtils } from "../../utils/RouteUtils";
import { useAppDispatch, useAppSelector } from "../redux/reduxHooks";
import { RootState } from "../redux/store";
import { getUser, getUserProfessionnel } from "../redux/userAction";
import { storageService } from "../service/storageService";
import { RouteApp } from "../model/RouteApp";
import { language, LanguageType } from "../../utils/LanguageUtils";
import { t } from "i18next";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import { UserProfessionnelResponse } from "../../professionnels/model/ProfessionnelModel";
import { MobileMenu } from "./MobileMenu";

const Logo = require("../../assets/Logo.PNG");

export default function Header() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [, setAvatar] = useState<AvatarResponse | undefined>(undefined);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const userSelector: UserStructureResponse = useAppSelector(
        (state: RootState) => state.user.data
    );

    const userSelect: UserProfessionnelResponse = useAppSelector(
        (state: RootState) => state.user.proData
    );
    const location = useLocation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const url = process.env.REACT_APP_API_URL;
    const idUser = storageService.getIdWithToken();

    const lang: LanguageType = language();

    const isUserProfessionnel = () => {
        const roles = storageService.getUserRolesWithToken();
        return roles.includes("ROLE_PROFESSIONNEL");
    };

    useEffect(() => {
        if (idUser && idUser.length > 1) {
            if (isUserProfessionnel()) {
                dispatch(getUserProfessionnel(idUser));
            } else {
                dispatch(getUser(idUser));
            }
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [dispatch, idUser]);

    useEffect(() => {
        if (userSelector.profileImage) {
            setAvatar(userSelector.profileImage);
        } else if (userSelect.profileImage) {
            setAvatar(userSelect.profileImage);
        }
    }, [userSelector, userSelect]);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (type: string) => {
        if (type === "Deconnexion") {
            dispatch(logout());
            setIsAuthenticated(false);
            navigate(RouteUtils.LOGIN);
        } else if (type === "Profil") {
            navigate(RouteUtils.PROFIL);
        }
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const hideButtonsRoutes = ["/", "/login", "/signup"];
    const shouldHideButtons = hideButtonsRoutes.includes(location.pathname);

    if (location.pathname === "/signup") {
        return null;
    }

    return (
        <Stack sx={{ width: "100%", marginBottom: "2rem", overflow: "hidden" }}>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: "#E53935",
                    width: "100%",
                    height: "70px",
                    overflow: "hidden",
                    boxShadow: 2,
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: isAuthenticated
                            ? "space-between"
                            : "center",
                        width: "100%",
                        overflow: "hidden",
                        height: "70px",
                        position: "fixed",
                    }}
                >
                    {!isAuthenticated && location.pathname === "/login" ? (
                        <Box
                            component="img"
                            sx={{ height: 62, cursor: "pointer" }}
                            alt="Smart Santé"
                            src={Logo}
                            onClick={() => navigate(RouteUtils.DASHBOARD)}
                        />
                    ) : (
                        <Stack
                            sx={{
                                flexDirection: "row",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "2rem",
                            }}
                        >
                            <Button
                                sx={{
                                    height: "100px",
                                    width: "fit-content",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: {
                                        xs: "flex",
                                        sm: "flex",
                                        md: "none",
                                    },
                                }}
                            >
                                <Box
                                    component="img"
                                    sx={{ height: 60, cursor: "pointer" }}
                                    alt="Smart Santé"
                                    className="center"
                                    src={Logo}
                                    onClick={() =>
                                        navigate(RouteUtils.DASHBOARD)
                                    }
                                />
                            </Button>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerToggle}
                                sx={{
                                    display: { xs: "flex", md: "none" },
                                    width: "40px",
                                    height: "40px",
                                }}
                            >
                                <StyledMenuIcon open={mobileOpen}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </StyledMenuIcon>
                            </IconButton>
                            <Box
                                component="img"
                                sx={{
                                    maxHeight: 62,
                                    display: {
                                        xs: "none",
                                        sm: "none",
                                        md: "block",
                                        cursor: "pointer",
                                    },
                                }}
                                alt="Smart Santé"
                                src={Logo}
                                onClick={() => navigate(RouteUtils.DASHBOARD)}
                            />
                            {isAuthenticated && !shouldHideButtons && (
                                <Box
                                    sx={{
                                        display: {
                                            xs: "none",
                                            sm: "none",
                                            md: "flex",
                                            width: "100%",
                                        },
                                    }}
                                >
                                    {userSelector.id && userSelector.societe ? (
                                        <>
                                            <List
                                                component="nav"
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    width: "70%",
                                                }}
                                            >
                                                <StyledListItemBtn
                                                    onClick={() =>
                                                        navigate(
                                                            RouteApp.DASHBOARD
                                                        )
                                                    }
                                                    selected={
                                                        location.pathname ===
                                                        RouteApp.DASHBOARD
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            lang.navbar
                                                                .dashboard
                                                        }
                                                    />
                                                </StyledListItemBtn>
                                                <StyledListItemBtn
                                                    onClick={() =>
                                                        navigate(
                                                            RouteApp.SEARCH
                                                        )
                                                    }
                                                    selected={
                                                        location.pathname ===
                                                        RouteApp.SEARCH
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            lang.navbar
                                                                .personnel
                                                        }
                                                    />
                                                </StyledListItemBtn>
                                                <StyledListItemBtn
                                                    onClick={() =>
                                                        navigate(
                                                            RouteApp.PROFESSIONNELS
                                                        )
                                                    }
                                                    selected={
                                                        location.pathname ===
                                                        RouteApp.PROFESSIONNELS
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            lang.navbar.contact
                                                        }
                                                    />
                                                </StyledListItemBtn>
                                                <StyledListItemBtn
                                                    onClick={() =>
                                                        navigate(
                                                            RouteApp.MY_PROJECTS
                                                        )
                                                    }
                                                    selected={
                                                        location.pathname ===
                                                        RouteApp.MY_PROJECTS
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            lang.navbar.projet
                                                        }
                                                    />
                                                </StyledListItemBtn>
                                                <StyledListItemBtn
                                                    onClick={() =>
                                                        navigate(
                                                            RouteApp.MY_ACCOUNT
                                                        )
                                                    }
                                                    selected={
                                                        location.pathname ===
                                                        RouteApp.MY_ACCOUNT
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            lang.navbar.profile
                                                        }
                                                    />
                                                </StyledListItemBtn>
                                            </List>
                                            <IconButton
                                                size="large"
                                                aria-label="account of current user"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                onClick={handleMenu}
                                                color="inherit"
                                                sx={{
                                                    borderRadius: "5px",
                                                    "&:hover": {
                                                        bgcolor: "#DC143C",
                                                    },
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    width={"fit-content"}
                                                >
                                                    {userSelector.societe}
                                                </Typography>
                                                <Avatar
                                                    src={
                                                        userSelector.profileImage
                                                            ? url +
                                                              userSelector
                                                                  .profileImage
                                                                  .contentUrl
                                                            : getEmptyAvatarStructure()
                                                    }
                                                    sx={{
                                                        width: 54,
                                                        height: 54,
                                                        ml: 2,
                                                    }}
                                                />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <>
                                            <List
                                                component="nav"
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    width: "70%",
                                                }}
                                            >
                                                <StyledListItemBtn
                                                    onClick={() =>
                                                        navigate(
                                                            RouteApp.DASHBOARD
                                                        )
                                                    }
                                                    selected={
                                                        location.pathname ===
                                                        RouteApp.DASHBOARD
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            lang.navbar
                                                                .dashboard
                                                        }
                                                    />
                                                </StyledListItemBtn>
                                                <StyledListItemBtn
                                                    onClick={() =>
                                                        navigate(
                                                            RouteApp.SEARCH
                                                        )
                                                    }
                                                    selected={
                                                        location.pathname ===
                                                        RouteApp.SEARCH
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            lang.navbar
                                                                .structures
                                                        }
                                                    />
                                                </StyledListItemBtn>
                                                <StyledListItemBtn
                                                    onClick={() =>
                                                        navigate(
                                                            RouteApp.PROFESSIONNELS
                                                        )
                                                    }
                                                    selected={
                                                        location.pathname ===
                                                        RouteApp.PROFESSIONNELS
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            lang.navbar.contact
                                                        }
                                                    />
                                                </StyledListItemBtn>
                                                <StyledListItemBtn
                                                    onClick={() =>
                                                        navigate(
                                                            RouteApp.MY_PROJECTS
                                                        )
                                                    }
                                                    selected={
                                                        location.pathname ===
                                                        RouteApp.MY_PROJECTS
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            lang.navbar
                                                                .disponibilite
                                                        }
                                                    />
                                                </StyledListItemBtn>
                                                <StyledListItemBtn
                                                    onClick={() =>
                                                        navigate(
                                                            RouteApp.MY_ACCOUNT
                                                        )
                                                    }
                                                    selected={
                                                        location.pathname ===
                                                        RouteApp.MY_ACCOUNT
                                                    }
                                                >
                                                    <ListItemText
                                                        primary={
                                                            lang.navbar.profile
                                                        }
                                                    />
                                                </StyledListItemBtn>
                                            </List>
                                            <IconButton
                                                size="large"
                                                aria-label="account of current user"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                onClick={handleMenu}
                                                color="inherit"
                                                sx={{
                                                    borderRadius: "5px",
                                                    "&:hover": {
                                                        bgcolor: "#DC143C",
                                                    },
                                                }}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    width={"fit-content"}
                                                >
                                                    {userSelect?.email}
                                                </Typography>
                                                <Avatar
                                                    src={
                                                        userSelect.profileImage
                                                            ? url +
                                                              userSelect
                                                                  .profileImage
                                                                  .contentUrl
                                                            : getEmptyAvatarStructure()
                                                    }
                                                    sx={{
                                                        width: 54,
                                                        height: 54,
                                                        ml: 2,
                                                    }}
                                                />
                                            </IconButton>
                                        </>
                                    )}
                                </Box>
                            )}
                        </Stack>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }}
                sx={{
                    display: { xs: "block", sm: "block", md: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: 240,
                    },
                }}
            >
                <MobileMenu
                    isStructure={!!userSelector.societe}
                    handleDrawerToggle={handleDrawerToggle}
                    handleClose={handleClose}
                    userSelector={userSelector}
                    t={t}
                />
            </Drawer>
            <StyledMenu
                id="menu-appbar"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableScrollLock={true}
                keepMounted
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <StyledMenuItem onClick={() => handleClose("Profil")}>
                    Profil
                    <ManageAccountsIcon sx={{ fontSize: "24px" }} />
                </StyledMenuItem>
                <StyledMenuItem onClick={() => handleClose("Deconnexion")}>
                    {t("button.profile.disconnect")}
                    <LogoutIcon sx={{ fontSize: "24px" }} />
                </StyledMenuItem>
            </StyledMenu>
        </Stack>
    );
}

const StyledListItemBtn = styled(ListItemButton)({
    backgroundColor: "#E53935",
    placeContent: "center",
    width: "50px",
    height: "70px",
    textAlign: "center",
    display: "grid",
    color: "#FFFFFF",
    cursor: "pointer",
    fontSize: "14px !important",
    "&:hover, :focus, :selection, :checked": {
        color: "#212121",
        backgroundColor: "#D32F2F",
    },
    "&::selection": {
        backgroundColor: "#D32F2F",
    },
    "&:checked": {
        backgroundColor: "#D32F2F",
    },
});

const StyledMenu = styled(Menu)({
    position: "fixed",
    zIndex: 10,
    height: "auto",
    top: 54,
    right: 16,
});

const StyledMenuItem = styled(MenuItem)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "1rem",
    textTransform: "uppercase",
    width: "300px",
});

const StyledMenuIcon = styled.div<{ open: boolean }>`
    width: 35px;
    height: 30px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #ffffff;
        border-radius: 10px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.35s ease-in-out;
    }

    span:nth-of-type(1) {
        top: ${(props) => (props.open ? "15px" : "5px")};
        transform: ${(props) =>
            props.open ? "rotate(135deg)" : "rotate(0deg)"};
    }

    span:nth-of-type(2) {
        top: 15px;
        opacity: ${(props) => (props.open ? 0 : 1)};
        left: ${(props) => (props.open ? "50px" : "0")};
    }

    span:nth-of-type(3) {
        top: ${(props) => (props.open ? "15px" : "25px")};
        transform: ${(props) =>
            props.open ? "rotate(-135deg)" : "rotate(0deg)"};
    }
`;
