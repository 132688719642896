import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { UserProfessionnelResponse } from "../professionnels/model/ProfessionnelModel";
import {
    getEmptyAvatarStructure,
    getPictureFromProfilImage,
} from "../utils/FormatUtils";
import { professionnelUpdateProfil } from "./redux/profilAction";
import { logout } from "../login/redux/authAction";
import { Modal } from "../common/components/Modal";
import { getUser, deleteUser } from "../common/redux/userAction";
import { storageService } from "../common/service/storageService";
import UploadDocument from "./UploadDocument";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { GetTranslatedProfessions } from "../utils/AvailableJobsUtils";

export const ProfessionnelForm = () => {
    const userSelector: UserProfessionnelResponse = useAppSelector(
        (state: RootState) => state.user.proData
    );
    const profilSelector: any = useAppSelector(
        (state: RootState) => state.profil
    );
    const [account, setAccount] =
        useState<UserProfessionnelResponse>(userSelector);
    const [profession, setProfession] = useState<string[]>(
        userSelector.profession || []
    );
    const [isOpenConfirmModal, setIsOpenConfirmModal] =
        useState<boolean>(false);
    const [isOpenConfirmModalDelete, setIsOpenConfirmModalDelete] =
        useState<boolean>(false);
    const [isOpenErrormModal, setIsOpenErrorModal] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const modalRef = useRef<HTMLDivElement | null>(null);
    const translatedProfessions = GetTranslatedProfessions();

    useEffect(() => {
        if (userSelector) {
            setAccount(userSelector);
        }
    }, [userSelector]);

    useEffect(() => {
        const idUser = storageService.getIdWithToken();
        if (idUser) dispatch(getUser(idUser));
    }, [dispatch]);

    useEffect(() => {
        const handleClose = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                setIsOpenConfirmModalDelete(false);
            }
        };

        if (isOpenConfirmModalDelete) {
            document.addEventListener("mousedown", handleClose);
        } else {
            document.removeEventListener("mousedown", handleClose);
        }

        return () => {
            document.removeEventListener("mousedown", handleClose);
        };
    }, [isOpenConfirmModalDelete]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!profilSelector.error) {
            const data = new FormData(event.currentTarget);

            if (!data.get("email") || !data.get("nom")) {
                alert(t("form.validation.error"));
                return;
            }

            dispatch(
                professionnelUpdateProfil({
                    nom: data.get("nom") as string,
                    prenom: data.get("prenom") as string,
                    email: data.get("email") as string,
                    telephone: data.get("telephone") as string,
                    description: data.get("description") as string,
                    profession: profession,
                })
            ).then((res: any) => {
                if (!res.error) {
                    setIsOpenConfirmModal(true);
                    setIsOpenErrorModal(false);
                } else {
                    setIsOpenConfirmModal(false);
                    setIsOpenErrorModal(true);
                }
            });
        }
    };

    const handleDeleteAccount = () => {
        dispatch(deleteUser()).then(() => {
            setIsOpenConfirmModalDelete(false);
            dispatch(logout());
            navigate("/login");
        });
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
            }}
        >
            <Paper
                sx={{
                    maxWidth: theme.breakpoints.values.md,
                    height: "100%",
                    mr: "auto",
                    ml: "auto",
                    mt: 8,

                    p: 5,
                    boxShadow:
                        "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
                }}
                elevation={12}
            >
                <Box
                    sx={{
                        marginTop: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: "300px",
                            maxHeight: "300px",
                            mt: "0px",
                        }}
                    >
                        {account.profileImage && (
                            <img
                                src={getPictureFromProfilImage(
                                    account.profileImage.contentUrl
                                )}
                                style={{
                                    maxWidth: "200px",
                                    maxHeight: "200px",
                                }}
                                alt="Profil"
                            />
                        )}
                        {!account.profileImage && (
                            <img
                                src={getEmptyAvatarStructure()}
                                style={{
                                    maxWidth: "200px",
                                    maxHeight: "200px",
                                }}
                                alt="Profil"
                            />
                        )}
                    </Box>
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit}
                        sx={{ mt: 3 }}
                    >
                        <Box
                            sx={{
                                mb: 4,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="nom"
                                        label={t("sign.up.nom")}
                                        name="nom"
                                        autoComplete="nom"
                                        defaultValue={account.nom}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="prenom"
                                        label={t("sign.up.prenom")}
                                        name="prenom"
                                        autoComplete="prenom"
                                        defaultValue={account.prenom}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        {t("profile.profession")}{" "}
                                        {Array.isArray(account.profession)
                                            ? account.profession.join(", ")
                                            : account.profession}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        sx={{
                                            backgroundColor: "white",
                                            borderRadius: "5px",
                                            width: "100%",
                                            height: "fit-content",

                                            "@media(width >= 1900px)": {
                                                width: "280px",
                                            },
                                        }}
                                        disablePortal
                                        freeSolo
                                        multiple
                                        id="combo-box"
                                        onChange={(event, newValue) => {
                                            setProfession(newValue);
                                        }}
                                        options={translatedProfessions.sort()}
                                        renderInput={(params) => (
                                            <TextField
                                                required
                                                {...params}
                                                name="profession"
                                                id="profession"
                                                label={t("sign.up.profession")}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="phone"
                                        label={t("sign.up.phone")}
                                        name="phone"
                                        autoComplete="tel-national"
                                        defaultValue={account.telephone}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label={t("auth.email")}
                                        name="email"
                                        autoComplete="email"
                                        defaultValue={account.email}
                                        autoFocus
                                    />
                                </Grid>
                            </Grid>
                            <UploadDocument />
                            <BtnContainer>
                                <UpdateBtn type="submit" variant="contained">
                                    {t("button.update.profile")}
                                </UpdateBtn>
                                <DeleteBtn
                                    onClick={() =>
                                        setIsOpenConfirmModalDelete(true)
                                    }
                                    variant="contained"
                                >
                                    {t("button.delete.profile")}
                                </DeleteBtn>
                            </BtnContainer>
                        </Box>
                    </Box>

                    {profilSelector.error && (
                        <Alert severity="error" className="errorMessage">
                            {profilSelector.error?.response?.data.detail}
                        </Alert>
                    )}
                    <div ref={modalRef}>
                        <Modal
                            open={isOpenConfirmModalDelete}
                            onValidation={() => handleDeleteAccount()}
                            onClose={() => setIsOpenConfirmModalDelete(false)}
                            title={t("modal.delete.profile.title")}
                            message={t("modal.delete.profile.description")}
                            titleButtonValidation={t("button.delete.project")}
                        />
                    </div>
                    <Modal
                        open={isOpenConfirmModal}
                        onValidation={() => setIsOpenConfirmModal(false)}
                        onClose={() => setIsOpenConfirmModal(false)}
                        title={t("modal.update.profile.title")}
                        message={t("modal.update.profile.description")}
                        titleButtonValidation={t("button.modal.valid")}
                    />
                </Box>
            </Paper>
        </Box>
    );
};

const UpdateBtn = styled(Button)({
    width: "180px",
    padding: "0.5rem",
    borderRadius: "10px",
    backgroundImage:
        "linear-gradient(to bottom, #b5e550, #abd854, #a1cb58, #97be5a, #8eb15c)",
    textTransform: "uppercase",
    fontSize: "14px !important",
});

const DeleteBtn = styled(Button)({
    width: "180px",
    padding: "0.5rem",
    borderRadius: "10px",
    backgroundImage:
        "linear-gradient(to right bottom, #ff0000, #ff322a, #ff4c46, #ff625f, #ff7676)",
    textTransform: "uppercase",
    fontSize: "14px !important",
});

const BtnContainer = styled(Stack)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: "1rem",
    marginTop: "2rem",

    "@media(width >= 768px)": {
        flexDirection: "row",
        gap: "2rem",
    },
});
