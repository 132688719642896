import { FunctionComponent } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserStructureResponse } from "../../structures/model/StructureModel";
import { IStateReducer } from "../model/IStateReducer";
import { RouteApp } from "../model/RouteApp";
import { Role } from "../model/User";
import { useAppSelector } from "../redux/reduxHooks";
import { RootState } from "../redux/store";

interface PrivateRouteProps {
    roles: Role[];
    children?: any;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
    roles,
    children,
}) => {
    const location = useLocation();

    const userSelector: IStateReducer<UserStructureResponse> = useAppSelector(
        (state: RootState) => state.user
    );
    const userAuth: any = useAppSelector((state: RootState) => state.auth.data);

    function getPermissionUserFromArray(roles: Role[]): boolean {
        let permission = false;

        roles.forEach((r: Role) => {
            if (
                (userSelector &&
                    userSelector.data &&
                    userSelector.data.roles &&
                    Object.values(userSelector.data.roles)[0] === r) ||
                (userAuth && userAuth.roles && userAuth.roles[0] === r) ||
                userSelector.isSuccess
            ) {
                permission = true;
            }
        });

        return permission;
    }

    return getPermissionUserFromArray(roles) ? (
        <>{children}</>
    ) : (
        <Navigate to={RouteApp.LOGIN} state={{ from: location }} replace />
    );
};

export default PrivateRoute;
