import Stack from "@mui/material/Stack";
import styled from "@emotion/styled";
import BusinessIcon from "@mui/icons-material/Business";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <Container>
            <StyledStack>
                <DescriptionSection>
                    <StyledLink
                        to="https://maps.app.goo.gl/a1rqJjmotmq1resY9"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Description>
                            <StyledBusinessIcon />
                            57 Rue Paradis <br />
                            13006 Marseille France{" "}
                        </Description>
                    </StyledLink>
                    <StyledLink to="tel:0491110030">
                        <Description>
                            <StyledPhoneIcon /> 04 91 11 00 30
                        </Description>
                    </StyledLink>
                    <StyledLink to="mailto:contact@smartsanteconseil.fr">
                        <Description>
                            <StyledMailIcon /> contact@smartsanteconseil.fr
                        </Description>
                    </StyledLink>
                </DescriptionSection>
            </StyledStack>
        </Container>
    );
};

export default Footer;

const Container = styled(Stack)({
    backgroundColor: "#E53935",
    width: "100%",
    position: "relative",
    zIndex: 100,
    bottom: 0,
    height: "auto",
    marginTop: "auto",
});

const StyledStack = styled(Stack)({
    height: "100%",
    gap: "2rem",
    width: "100%",
    flexDirection: "column",
    padding: "1rem",

    "@media(min-width:990px)": {
        flexDirection: "row",
        height: "90px",
    },
});

const DescriptionSection = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    listStyle: "none",
    height: "100%",
    gap: "2rem",
    justifyContent: "center",

    "@media(min-width:900px)": {
        flexDirection: "row",
        justifyContent: "space-around",
    },
});

const StyledLink = styled(Link)({
    color: "#FFFFFF",
    fontWeight: "bold",

    "&:hover": {
        color: "##FFFFFF !important",
        outline: "none",
    },

    "&:focus": {
        color: "##FFFFFF !important",
        outline: "none",
    },

    "&:active": {
        color: "##FFFFFF !important",
    },

    "@media(min-width:768px)": {
        textDecoration: "none",
    },
});

const Description = styled("li")({
    display: "flex",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    fontSize: "15px",
    gap: "0.5rem",

    "@media(min-width:768px)": {
        fontSize: "16px",
    },

    "@media(min-width:990px)": {
        flexDirection: "row",
        fontSize: "18px",
    },
});

const StyledBusinessIcon = styled(BusinessIcon)({
    fontSize: "40px",
    color: "#FFFFFF",
});

const StyledPhoneIcon = styled(PhoneIcon)({
    fontSize: "40px",
    color: "#FFFFFF",
});

const StyledMailIcon = styled(AlternateEmailIcon)({
    fontSize: "40px",
    color: "#FFFFFF",
});
