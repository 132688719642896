import { Box, Button, DialogContent, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { language, LanguageType } from "../../utils/LanguageUtils";
import LogoConfirmation from "../../assets/LogoConfirmation.png";
import styled from "@emotion/styled";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CancelIcon from "@mui/icons-material/Cancel";

interface PopupProps {
    open: boolean;
    onClose: () => void;
    message?: string;
    arrayOfMessage?: string[];
    title?: string;
    onValidation?: () => void;
    titleButtonValidation?: string;
    titleButtonCancel?: string;
    credentials?: string;
    children?: React.ReactNode;
}

export const ConfirmationModal = (props: PopupProps) => {
    const lang: LanguageType = language();
    const message = lang.modalConfirmation;

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            disableEscapeKeyDown={true}
            className="popup-background"
        >
            <Title className="popup-header">
                <img
                    src={LogoConfirmation}
                    alt="Logo confirmation"
                    width="120px"
                    height="120px"
                />
            </Title>
            <DialogContent>
                {props.message ? (
                    <p>{props.message}</p>
                ) : (
                    <Typography sx={{ whiteSpace: "pre-line", mt: 2 }}>
                        {message}
                    </Typography>
                )}
                {props.arrayOfMessage && (
                    <div>
                        {props.arrayOfMessage.map((e, i) => {
                            return <p key={i}>{e}</p>;
                        })}
                    </div>
                )}

                <Box
                    sx={{
                        textAlign: "center",
                        mt: 6,
                        display: "flex",
                        flexDirection: "row",
                        gap: 5,
                    }}
                    className="popup-actions-button"
                >
                    <ButtonCancel
                        onClick={props.onClose}
                        size="large"
                        variant="contained"
                    >
                        <CancelIcon fontSize="large" />
                    </ButtonCancel>
                    {props.onValidation && (
                        <ButtonConfirm
                            onClick={props.onValidation}
                            size="large"
                            variant="contained"
                            className="popup-validate-button"
                        >
                            <AssignmentTurnedInIcon fontSize="large" />
                        </ButtonConfirm>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};

const Title = styled(DialogTitle)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const ButtonConfirm = styled(Button)({
    width: "120px",
    backgroundColor: "#48bf53",
    color: "white",

    ":hover": {
        opacity: 0.9,
        backgroundColor: "#48bf53",
    },
});

const ButtonCancel = styled(Button)({
    width: "120px",
    backgroundColor: "#ac1e19",
    color: "white",

    ":hover": {
        opacity: 0.9,
        backgroundColor: "#ac1e19",
    },
});
