import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { RouteAPI, api } from "../../common/service/apiService";
import { SignUpData } from "../../login/model/SignUpData";
import { setPicture, setSignUpProfessionnel } from "./signUpSlice";
import { UserProfessionnelResponse } from "../../professionnels/model/ProfessionnelModel";

interface AvatarResponse {
    contentUrl: string;
    id: string;
}

export interface ResponseViolations {
    detail: string;
    title: string;
    type: string;
    violations: any;
}

export const postAvatar = createAsyncThunk<AvatarResponse, File>(
    "profileImage/upload",
    async (profileImage: File, thunkAPI: any) => {
        try {
            const response: AvatarResponse = await api(
                RouteAPI.UPLOAD_IMAGE,
                "POST",
                { file: profileImage }
            ).then((res) => res.data);
            thunkAPI.dispatch(setPicture(response));
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(
                error as AxiosError<ResponseViolations>
            );
        }
    }
);

export const signUpProfessionnel = createAsyncThunk<
    UserProfessionnelResponse,
    SignUpData
>("professionnel/create", async (signUpData: SignUpData, thunkAPI: any) => {
    try {
        const response: UserProfessionnelResponse = await api(
            RouteAPI.SIGN_UP_PROFESSIONNEL,
            "POST",
            signUpData
        ).then((res) => res.data);
        thunkAPI.dispatch(setSignUpProfessionnel(response));
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(
            error as AxiosError<ResponseViolations>
        );
    }
});
