import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { WorkerCard } from "../cards/WorkerCard";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { DisponibiliteProfessionnel } from "../search/model/Disponibilite";
import { RouteUtils } from "../utils/RouteUtils";
import {
    getInterestProfessionnels,
    getInterestStructure,
} from "./redux/professionnelAction";
import { useTranslation } from "react-i18next";

import { StructureCard } from "../cards/StructureCard";
import { DisponibiliteStructure } from "../structures/model/StructureModel";
import EmptySearch from "../common/components/EmptySearch";

export const ProfessionnelPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const idUser = useAppSelector((state: RootState) => state.user.data.id);
    const idUserProfessionnel = useAppSelector(
        (state: RootState) => state.userPro.data.id
    );

    const [professionnelMatched, setProfessionnelMatched] = useState<
        DisponibiliteProfessionnel[] | []
    >([]);
    const [, setStructureMatched] = useState<DisponibiliteStructure[] | []>([]);

    const structuresMatchedSelector = useAppSelector(
        (state: RootState) => state.structure.data
    );

    const professionnelMatchedSelector = useAppSelector(
        (state: RootState) => state.professionnel.data
    );

    const userSelector = useAppSelector((state: RootState) => state.user.data);

    useEffect(() => {
        dispatch(getInterestStructure(idUserProfessionnel));
        dispatch(getInterestProfessionnels(idUser));
    }, [dispatch, idUser, idUserProfessionnel]);

    useEffect(() => {
        if (userSelector.societe) {
            if (professionnelMatchedSelector.length > 0) {
                setProfessionnelMatched(professionnelMatchedSelector);
            }
        } else {
            if (structuresMatchedSelector.length > 0) {
                setStructureMatched(structuresMatchedSelector);
            }
        }
    }, [
        professionnelMatchedSelector,
        structuresMatchedSelector,
        userSelector.societe,
    ]);

    const handleBackClick = useCallback(() => {
        navigate(RouteUtils.SEARCH);
    }, [navigate]);

    const personnelsCalled = useCallback(
        (props: DisponibiliteProfessionnel, i: number) => (
            <Grid item key={i} m={2} lg={5}>
                <WorkerCard {...props} />
            </Grid>
        ),
        []
    );

    const companyCalled = (props: DisponibiliteStructure, i: number) => {
        return (
            <Grid item card={6} key={i} lg={9} xs={7}>
                <StructureCard {...props} key={i} />
            </Grid>
        );
    };

    const contactCount = useMemo(() => {
        if (userSelector.societe) {
            if (professionnelMatched.length > 0) {
                return professionnelMatched.length;
            }
        } else {
            if (structuresMatchedSelector.length > 0) {
                return structuresMatchedSelector.length;
            }
        }
        return 0;
    }, [professionnelMatched, structuresMatchedSelector, userSelector.societe]);

    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                gap: 4,
                marginBottom: "3.5rem",
            }}
        >
            <Stack
                sx={{
                    width: "100%",
                    marginTop: "2.5rem",
                }}
            >
                {" "}
                {userSelector.societe ? (
                    <Typography
                        variant="h6"
                        mt={3}
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <Button
                            variant="text"
                            size="medium"
                            sx={{
                                padding: 0,
                                marginTop: "40px",
                                display: "contents",
                            }}
                            onClick={handleBackClick}
                        >
                            <ArrowBackIcon
                                fontSize="medium"
                                sx={{ height: 30, width: 30, mr: 1, ml: 2 }}
                            />
                        </Button>
                        {t("dashboard.personal.contacted")}
                        <Box
                            sx={{
                                backgroundColor: "#3d3d3d",
                                padding: 2,
                                color: "white",
                                borderRadius: "50%",
                                width: 24,
                                height: 24,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                ml: 1,
                            }}
                        >
                            {contactCount}
                        </Box>
                    </Typography>
                ) : (
                    <Typography
                        variant="h6"
                        mt={3}
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <Button
                            variant="text"
                            size="medium"
                            sx={{
                                padding: 0,
                                marginTop: "40px",
                                display: "contents",
                            }}
                            onClick={handleBackClick}
                        >
                            <ArrowBackIcon
                                fontSize="medium"
                                sx={{ height: 30, width: 30, mr: 1, ml: 2 }}
                            />
                        </Button>
                        {t("dashboard.personal.structure")}
                        <Box
                            sx={{
                                backgroundColor: "#3d3d3d",
                                padding: 2,
                                color: "white",
                                borderRadius: "50%",
                                width: 24,
                                height: 24,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                ml: 1,
                            }}
                        >
                            {contactCount}
                        </Box>
                    </Typography>
                )}
                {contactCount === 0 && <EmptySearch />}
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "2.5rem",
                    }}
                >
                    {userSelector.societe
                        ? professionnelMatched.map(personnelsCalled)
                        : structuresMatchedSelector.map(
                              (item: DisponibiliteStructure, i: number) => {
                                  return companyCalled(item, i);
                              }
                          )}
                </Box>
            </Stack>
        </Box>
    );
};
