import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ResponseMessage } from "../model/ResponseMessage";
import { getUserProfessionnel } from "./userAction";

import {
    UserProfessionnelResponse,
    emptyUserProfessionnel,
} from "../../professionnels/model/ProfessionnelModel";
import { initialStateReducer, IStateReducer } from "../model/IStateReducer";

const initialUsersState: IStateReducer<UserProfessionnelResponse> & {
    data: UserProfessionnelResponse;
} = {
    ...initialStateReducer,
    data: emptyUserProfessionnel,
};

export const userProSlice = createSlice({
    name: "user",
    initialState: initialUsersState,
    reducers: {
        setUserConnected: (
            state,
            action: PayloadAction<UserProfessionnelResponse>
        ) => {
            if ("professionalId" in action.payload) {
                state.data = action.payload as UserProfessionnelResponse;
            }
        },
        disconnectUser: (state) => {
            state.data = emptyUserProfessionnel;
        },
        setIsSuccess: (state, action: PayloadAction<boolean>) => {
            state.isSuccess = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getUserProfessionnel.pending, (state) => {
            state.isLoader = true;
            state.isSuccess = false;
            state.error = undefined;
        });
        builder.addCase(getUserProfessionnel.fulfilled, (state, action) => {
            state.data = action.payload;
            state.isLoader = false;
            state.isSuccess = true;
        });
        builder.addCase(
            getUserProfessionnel.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoader = false;
                state.isSuccess = false;
                state.error = action.payload as AxiosError<ResponseMessage>;
            }
        );
    },
});

// Action creators are generated for each case reducer function
export const { setUserConnected, setIsSuccess, disconnectUser } =
    userProSlice.actions;

export default userProSlice;
