import { Box, Grid, Pagination, Stack } from "@mui/material";
import { useEffect, useMemo, useState, useCallback, Key } from "react";
import { SearchComponent } from "./SearchComponent";
import { WorkerCard } from "../cards/WorkerCard";
import { useAppDispatch, useAppSelector } from "../common/redux/reduxHooks";
import { RootState } from "../common/redux/store";
import { DisponibiliteProfessionnel } from "./model/Disponibilite";
import { getDisponibilities } from "./redux/searchAction";
import { StructureCard } from "../cards/StructureCard";
import { DisponibiliteStructure } from "../structures/model/StructureModel";
import "./SearchPage.css";
import EmptySearch from "../common/components/EmptySearch";

export const SearchPage = () => {
    const [page, setPage] = useState<number>(1);

    const disponibilities = useAppSelector(
        (state: RootState) => state.search.data
    );
    const structures = useAppSelector(
        (state: RootState) => state.search.strucData
    );

    const itemsPerPage = 12;

    const dispatch = useAppDispatch();
    const userSelector = useAppSelector((state: RootState) => state.user.data);
    const userSelect = useAppSelector((state: RootState) => state.user.proData);

    useEffect(() => {
        dispatch(getDisponibilities(null));
    }, [dispatch]);

    const handleChangePage = useCallback((e: any, p: number) => {
        setPage(p);
    }, []);

    const filteredDisponibilities = useMemo(() => {
        if (!disponibilities) return [];
        return disponibilities.filter(
            (item: { isInterested: { status: string } }) =>
                !item.isInterested || item.isInterested.status !== "accepted"
        );
    }, [disponibilities]);

    const filteredStructures = useMemo(() => {
        if (!structures || !userSelect.profession) return [];

        const userProfessions = Array.isArray(userSelect.profession)
            ? userSelect.profession
            : [userSelect.profession];

        return structures.filter((structure: DisponibiliteStructure) => {
            const structureProfessions = Array.isArray(structure.profession)
                ? structure.profession
                : [structure.profession];

            return structureProfessions.some((profession) =>
                userProfessions.includes(profession)
            );
        });
    }, [structures, userSelect.profession]);

    const paginatedDisponibilities = useMemo(() => {
        return filteredDisponibilities.slice(
            (page - 1) * itemsPerPage,
            page * itemsPerPage
        );
    }, [filteredDisponibilities, page, itemsPerPage]);

    const paginatedStructures = useMemo(() => {
        if (!filteredStructures) return [];
        return filteredStructures.slice(
            (page - 1) * itemsPerPage,
            page * itemsPerPage
        );
    }, [filteredStructures, page, itemsPerPage]);

    const count = useMemo(() => {
        if (userSelector.societe) {
            return Math.ceil(filteredDisponibilities.length / itemsPerPage);
        } else {
            return Math.ceil(filteredStructures.length / itemsPerPage);
        }
    }, [
        filteredDisponibilities.length,
        filteredStructures.length,
        userSelector.societe,
    ]);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Stack sx={{ mt: 3 }}>
                <SearchComponent />
            </Stack>
            {userSelector.societe ? (
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}
                >
                    {paginatedDisponibilities.length > 0 ? (
                        paginatedDisponibilities.map(
                            (
                                item: JSX.IntrinsicAttributes &
                                    DisponibiliteProfessionnel,
                                i: Key | null | undefined
                            ) => (
                                <Grid item key={i} m={1} lg={5}>
                                    <WorkerCard key={i} {...item} />
                                </Grid>
                            )
                        )
                    ) : (
                        <EmptySearch />
                    )}
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                    }}
                >
                    {paginatedStructures.length > 0 ? (
                        paginatedStructures.map(
                            (
                                item: JSX.IntrinsicAttributes &
                                    DisponibiliteStructure,
                                i: Key | null | undefined
                            ) => (
                                <Grid item key={i} m={1} lg={5}>
                                    <StructureCard key={i} {...item} />
                                </Grid>
                            )
                        )
                    ) : (
                        <EmptySearch isJobSearch />
                    )}
                </Box>
            )}

            {count > 1 && (
                <Pagination
                    count={count}
                    sx={{ justifyContent: "center", mt: 2, mb: 2 }}
                    defaultPage={1}
                    size="medium"
                    page={page}
                    variant="outlined"
                    onChange={handleChangePage}
                />
            )}
        </Box>
    );
};
