import React, { useCallback, useMemo } from "react";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import styled from "@emotion/styled";

interface StyledDatePickerProps {
    open: boolean;
    value: dayjs.Dayjs | null;
    onOpen: () => void;
    onClose: () => void;
    onChange: (date: any) => void;
    label: string;
    showTime?: boolean;
}

const StyledDatePicker: React.FC<StyledDatePickerProps> = React.memo(
    ({ open, value, onOpen, onClose, onChange, showTime, label }) => {
        const parsedValue = useMemo(
            () => (value ? dayjs(value) : null),
            [value]
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const handleDateChange = useCallback(
            debounce((newValue) => {
                if (dayjs.isDayjs(newValue)) {
                    onChange(newValue);
                } else {
                    onChange(null);
                }
            }),
            [onChange]
        );

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                {showTime ? (
                    <DateTimePickerTest
                        open={open}
                        value={parsedValue}
                        onOpen={onOpen}
                        onClose={onClose}
                        onChange={handleDateChange}
                        slotProps={{
                            textField: {
                                onClick: onOpen,
                                label: label,
                            },
                        }}
                    />
                ) : (
                    <DatePickerTest
                        open={open}
                        value={parsedValue}
                        onOpen={onOpen}
                        onClose={onClose}
                        onChange={handleDateChange}
                        slotProps={{
                            textField: {
                                onClick: onOpen,
                                label: label,
                            },
                        }}
                    />
                )}
            </LocalizationProvider>
        );
    }
);

export default StyledDatePicker;

const DateTimePickerTest = styled(DateTimePicker)({
    width: "160px",
    backgroundColor: "white",
    borderRadius: "5px",
    color: "#3b3c36",

    "@media(width >= 1200px)": {
        width: "200px",
    },

    "@media(width >= 1900px)": {
        width: "280px",
    },
});

const DatePickerTest = styled(DatePicker)({
    width: "160px",
    backgroundColor: "white",
    borderRadius: "5px",
    color: "#3b3c36",

    "@media(width >= 1200px)": {
        width: "200px",
    },

    "@media(width >= 1900px)": {
        width: "280px",
    },
});
