import { Stack, styled, Typography } from "@mui/material";
import { t } from "i18next";
import EmptyResultIcon from "../../assets/EmptySearch.png";
import EmptyJobsIcon from "../../assets/EmptyJobs.png";

interface EmptySearchProps {
    isJobSearch?: boolean;
}

export default function EmptySearch({
    isJobSearch,
}: EmptySearchProps): JSX.Element {
    return (
        <EmptySearchBox>
            <EmptyImgIcon
                src={isJobSearch ? EmptyJobsIcon : EmptyResultIcon}
                alt="No results"
            />
            <Title>
                {isJobSearch
                    ? t("search.no.jobs.title")
                    : t("search.no.results.oops")}
            </Title>
            <Description>
                {isJobSearch
                    ? t("search.no.jobs.results")
                    : t("search.no.results.search")}
            </Description>
            <Description>
                {isJobSearch
                    ? t("search.no.jobs.try")
                    : t("search.no.results.try")}
            </Description>
        </EmptySearchBox>
    );
}

const EmptySearchBox = styled(Stack)({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "4rem 0 4rem 0",
    gap: "1rem",
});

const EmptyImgIcon = styled("img")({
    width: "60%",
    height: "100%",
    objectFit: "cover",

    "@media(width >= 768px)": {
        width: "20%",
    },
});

const Title = styled(Typography)({
    fontSize: "2rem",
    fontWeight: "bold",
});

const Description = styled(Typography)({
    fontSize: "1rem",
});
