import { SetStateAction, useEffect, useState } from "react";
import { Button, Box, Typography, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import StructureForm from "../common/components/StructureForm";
import SoignantForm from "../common/components/SoignantForm";
import SignUpCover from "../../src/assets/SignUpCover.png";
import styled from "@emotion/styled";
import MaduraWall from "../../src/assets/MaduraWall.png";

export default function SignUpPage() {
    const description = [
        "Bienvenue chez Smart Santé Conseil, votre partenaire au quotidien",
        "Avec plus de 5000 professionnels de santé dévoués",
        "Avec plus de 200 clients satisfaits qui nous ont accordé leur confiance",
        "Nos clients ont exprimé leur grande satisfaction à l'égard de nos services",
    ];
    const [selectedForm, setSelectedForm] = useState<"Structure" | "Soignant">(
        "Structure"
    );
    const [currentIndex, setCurrentIndex] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(
                (prevIndex) => (prevIndex + 1) % description.length
            );
        }, 4000);

        return () => clearInterval(interval);
    }, [description.length]);

    const handleClick = (index: SetStateAction<number>) => {
        setCurrentIndex(index);
    };

    return (
        <StyledStack>
            <FormContainer>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h5"
                        sx={{ mb: 2, mt: 2 }}
                    >
                        {t("sign.up.registration")}
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            variant={
                                selectedForm === "Structure"
                                    ? "contained"
                                    : "outlined"
                            }
                            onClick={() => setSelectedForm("Structure")}
                            sx={{ mr: 2 }}
                        >
                            Structure
                        </Button>
                        <Button
                            variant={
                                selectedForm === "Soignant"
                                    ? "contained"
                                    : "outlined"
                            }
                            onClick={() => setSelectedForm("Soignant")}
                        >
                            Soignant
                        </Button>
                    </Box>

                    {selectedForm === "Structure" ? (
                        <StructureForm />
                    ) : (
                        <SoignantForm />
                    )}
                </Box>
            </FormContainer>
            <ImgContainer>
                <Div>
                    <Image
                        src={SignUpCover}
                        alt="Sign Up Cover"
                        width="380px"
                        height="480px"
                    />
                    {description.map((text, index) => (
                        <TextSlider
                            key={index}
                            className={index === currentIndex ? "active" : ""}
                        >
                            {text}
                        </TextSlider>
                    ))}
                    <BubbleContainer>
                        {description.map((_, index) => (
                            <Bubble
                                key={index}
                                className={
                                    index === currentIndex ? "active" : ""
                                }
                                onClick={() => handleClick(index)}
                            />
                        ))}
                    </BubbleContainer>
                </Div>
            </ImgContainer>
        </StyledStack>
    );
}

const StyledStack = styled(Stack)({
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    "@media(width >= 990px)": {
        justifyContent: "space-between",
    },
});

const Image = styled("img")({
    width: "60%",
    height: "auto",
    objectFit: "cover",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "15px",
});

const ImgContainer = styled("div")({
    display: "none",

    "@media(width >= 990px)": {
        backgroundImage: `url(${MaduraWall})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        width: "50%",
        height: "820px",
        alignItems: "start",
        justifyContent: "center",
        boxShadow: "20px 0 10px -20px rgba(0,0,0,0.45) inset",
    },

    "@media(width >= 1900px)": {
        height: "100dvh",
    },
});

const Div = styled("div")({
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    marginTop: "6rem",
});

const TextSlider = styled("div")({
    position: "absolute",
    top: "85%",
    left: "38.5%",
    transform: "translate(-50%, -50%)",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "0 10px 10px 0",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    width: "280px",
    textAlign: "start",
    transition: "opacity 0.5s ease-in-out",
    opacity: 0,
    "&.active": {
        opacity: 1,
    },

    "@media(width >= 1900px)": {
        left: "34.5%",
    },
});

const BubbleContainer = styled("div")({
    position: "absolute",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    gap: "10px",
});

const Bubble = styled("button")({
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    backgroundColor: "white",
    border: "none",
    opacity: 0.5,
    cursor: "pointer",
    "&.active": {
        opacity: 1,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
    },
});

const FormContainer = styled(Stack)({
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    boxShadow:
        "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 7px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",

    "@media(width >= 990px)": {
        marginLeft: "5rem",
    },

    "@media(width >= 1900px)": {
        marginLeft: "8rem",
    },
});
