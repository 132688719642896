export const getPictureFromProfilImage = (url: string) => {
    return process.env.REACT_APP_API_URL + url;
};

export const getEmptyAvatarStructure = () => {
    const emptyAvatar = require("../assets/emptyAvatar.png");
    return emptyAvatar;
};

export const getEmptyAvatarProfessionnel = () => {
    const emptyAvatar = require("../assets/emptyProfessionnel.png");
    return emptyAvatar;
};

export const formatTime = (time: string | null) => {
    if (!time) return "--:--";

    return new Date(time).toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC",
    });
};
