import { Box, Button, DialogContent, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { language, LanguageType } from "../../utils/LanguageUtils";
import { useTranslation } from "react-i18next";
import LogoConfirmed from "../../assets/LogoConfirmed.gif";

interface PopupProps {
    open: boolean;
    onClose: () => void;
    message?: string;
    arrayOfMessage?: string[];
    title?: string;
    onValidation?: () => void;
    titleButtonValidation?: string;
    onCancel?: () => void;
    titleButtonCancel?: string;
    credentials?: string;
    showBackground?: boolean;
}

export const Modal = (props: PopupProps) => {
    const lang: LanguageType = language();
    const message = lang.modalSmart;
    const { t } = useTranslation();

    return (
        <Dialog open={props.open} className="popup-background">
            {props.showBackground && (
                <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "70%",
                        backgroundImage: `url(${LogoConfirmed})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        zIndex: 1,
                        opacity: 0.4,
                    }}
                />
            )}

            <DialogTitle
                className="popup-header"
                sx={{
                    display: "flex",

                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {props.title}
            </DialogTitle>

            <DialogContent
                sx={{
                    position: "relative",
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                        zIndex: 2,
                    }}
                >
                    {props.message ? (
                        <Typography sx={{ whiteSpace: "pre-line" }}>
                            {props.message}
                        </Typography>
                    ) : (
                        <Typography sx={{ whiteSpace: "pre-line" }}>
                            {message}
                        </Typography>
                    )}
                    {props.arrayOfMessage && (
                        <div>
                            {props.arrayOfMessage.map((e, i) => (
                                <Typography key={i}>{e}</Typography>
                            ))}
                        </div>
                    )}

                    {props.credentials ? (
                        <Typography>{props.credentials}</Typography>
                    ) : (
                        <Typography sx={{ whiteSpace: "pre-line" }}>
                            {t("forgot.password.description.modal")}
                        </Typography>
                    )}
                </Box>

                <Box
                    sx={{ textAlign: "center", mt: 3 }}
                    className="popup-actions-button"
                >
                    {props.onCancel && (
                        <Button
                            onClick={props.onCancel}
                            sx={{ mr: 3 }}
                            color="error"
                            size="large"
                            variant="outlined"
                            className="popup-cancel-button"
                        >
                            {props.titleButtonCancel}
                        </Button>
                    )}
                    {props.onValidation && (
                        <Button
                            onClick={props.onValidation}
                            color="success"
                            size="large"
                            variant="contained"
                            className="popup-validate-button"
                        >
                            {props.titleButtonValidation}
                        </Button>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    );
};
