import { Stack, styled, Typography } from "@mui/material";
import { t } from "i18next";
import EmptyProjectIcon from "../../assets/EmptyProject.png";

export default function EmptyProject(): JSX.Element {
    return (
        <EmptySearchBox>
            <EmptyImgIcon src={EmptyProjectIcon} alt="No results" />
            <Title>{t("search.no.results.oops")}</Title>
            <Description>{t("search.no.results.search")}</Description>
            <Description>{t("search.no.results.try")} 😊</Description>
        </EmptySearchBox>
    );
}

const EmptySearchBox = styled(Stack)({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "4rem 0 4rem 0",
    gap: "1rem",
});

const EmptyImgIcon = styled("img")({
    width: "60%",
    height: "100%",
    objectFit: "cover",

    "@media(width >= 768px)": {
        width: "20%",
    },
});

const Title = styled(Typography)({
    fontSize: "2rem",
    fontWeight: "bold",
});

const Description = styled(Typography)({
    fontSize: "1rem",
});
