import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import {
    initialStateReducer,
    IStateReducer,
} from "../../common/model/IStateReducer";
import { ResponseMessage } from "../../common/model/ResponseMessage";
import { getInterestStructure } from "../../professionnels/redux/professionnelAction";
import { defaultStructureProps } from "../../cards/WorkerCardStructure";
import { DisponibiliteStructure } from "../../search/model/Disponibilite";

const initialUsersState: IStateReducer<DisponibiliteStructure[]> = {
    ...initialStateReducer,
    data: [defaultStructureProps],
};
export const structureSlice = createSlice({
    name: "structure",
    initialState: initialUsersState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getInterestStructure.pending, (state) => {
            state.isLoader = true;
            state.isSuccess = false;
            state.error = undefined;
            state.requestType = "GET";
        });
        builder.addCase(getInterestStructure.fulfilled, (state, action) => {
            state.data = action.payload;
            state.isLoader = false;
            state.isSuccess = true;
            state.requestType = "GET";
        });
        builder.addCase(
            getInterestStructure.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoader = false;
                state.isSuccess = false;
                state.error = action.payload as AxiosError<ResponseMessage>;
                state.requestType = "GET";
            }
        );
    },
});

// Action creators are generated for each case reducer function

export default structureSlice;
