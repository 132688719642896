import React from "react";
import { FunctionComponent } from "react";
import { Box, Typography } from "@mui/material";

export const NotFoundPage: FunctionComponent = () => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                minHeight: "100vh",
                backgroundColor: "#3d3d3d",
            }}
        >
            <Typography variant="h1" style={{ color: "white" }}>
                404
            </Typography>
            <Typography variant="h6" style={{ color: "white" }}>
                La page demandée n'existe pas.
            </Typography>
        </Box>
    );
};
