import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ResponseMessage } from "../model/ResponseMessage";
import { getUser, getUserProfessionnel } from "./userAction";
import {
    emptyUserStructure,
    UserStructureResponse,
} from "../../structures/model/StructureModel";
import {
    UserProfessionnelResponse,
    emptyUserProfessionnel,
} from "../../professionnels/model/ProfessionnelModel";
import { initialStateReducer, IStateReducer } from "../model/IStateReducer";

const initialUsersState: IStateReducer<UserStructureResponse> & {
    proData: UserProfessionnelResponse;
} = {
    ...initialStateReducer,
    data: emptyUserStructure,
    proData: emptyUserProfessionnel,
};

export const userSlice = createSlice({
    name: "users",
    initialState: initialUsersState,
    reducers: {
        setUserConnected: (
            state,
            action: PayloadAction<
                UserStructureResponse | UserProfessionnelResponse
            >
        ) => {
            if ("structureId" in action.payload) {
                state.data = action.payload as UserStructureResponse;
            } else if ("professionalId" in action.payload) {
                state.proData = action.payload as UserProfessionnelResponse;
            }
        },
        disconnectUser: (state) => {
            state.data = emptyUserStructure;
            state.proData = emptyUserProfessionnel;
        },
        setIsSuccess: (state, action: PayloadAction<boolean>) => {
            state.isSuccess = action.payload;
        },
    },
    extraReducers(builder) {
        // **************** GET USER STRUCTURE *****************
        builder.addCase(getUser.pending, (state) => {
            state.isLoader = true;
            state.isSuccess = false;
            state.error = undefined;
        });
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.data = action.payload;
            state.isLoader = false;
            state.isSuccess = true;
        });
        builder.addCase(
            getUser.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoader = false;
                state.isSuccess = false;
                state.error = action.payload as AxiosError<ResponseMessage>;
            }
        );

        // **************** GET USER PROFESSIONNEL *****************
        builder.addCase(getUserProfessionnel.pending, (state) => {
            state.isLoader = true;
            state.isSuccess = false;
            state.error = undefined;
        });
        builder.addCase(getUserProfessionnel.fulfilled, (state, action) => {
            state.proData = action.payload;
            state.isLoader = false;
            state.isSuccess = true;
        });
        builder.addCase(
            getUserProfessionnel.rejected,
            (state, action: PayloadAction<any>) => {
                state.isLoader = false;
                state.isSuccess = false;
                state.error = action.payload as AxiosError<ResponseMessage>;
            }
        );
    },
});

// Action creators are generated for each case reducer function
export const { setUserConnected, setIsSuccess, disconnectUser } =
    userSlice.actions;

export default userSlice;
